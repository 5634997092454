<template>
  <div>
    <v-card class="steps" outlined>
      <v-card-text
        :class="stepColor(steps.STUDENT_QUALIFICATIONS)"
        @click="setStudentCurrentStepDirectly(steps.STUDENT_QUALIFICATIONS)"
      >
        <v-row>
          <v-col cols="10"> Step 1: Student Qualifications </v-col>
          <v-col cols="2">
            <v-icon v-if="studentQualified" color="success"> mdi-check </v-icon>
            <v-icon v-else-if="!studentQualified" color="warning">
              mdi-progress-clock
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <div v-if="studentQualified">
        <v-card-text
          :class="stepColor(steps.STUDENT_INFORMATION)"
          @click="setStudentCurrentStepDirectly(steps.STUDENT_INFORMATION)"
        >
          <v-row>
            <v-col cols="10"> Step 2: Student Information </v-col>
            <v-col cols="2">
              <v-icon v-if="studentInformationComplete" color="success">
                mdi-check
              </v-icon>
              <v-icon v-else-if="!studentInformationComplete" color="warning">
                mdi-progress-clock
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text
          :class="stepColor(steps.EXCELLENCE_AGREEMENT)"
          @click="setStudentCurrentStepDirectly(steps.EXCELLENCE_AGREEMENT)"
        >
          <v-row>
            <v-col cols="10"> Step 3: Excellence Agreement </v-col>
            <v-col cols="2">
              <v-icon v-if="studentExcellenceAgreementComplete" color="success">
                mdi-check
              </v-icon>
              <v-icon
                v-else-if="!studentExcellenceAgreementComplete"
                color="warning"
              >
                mdi-progress-clock
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />

        <v-card-text
          :class="stepColor(steps.SCHOLARSHIP_SELECTION)"
          @click="setStudentCurrentStepDirectly(steps.SCHOLARSHIP_SELECTION)"
        >
          <v-row>
            <v-col cols="10"> Step 4: Scholarship Selection </v-col>
            <v-col cols="2">
              <v-icon
                v-if="studentScholarshipSelectionComplete"
                color="success"
              >
                mdi-check
              </v-icon>
              <v-icon
                v-else-if="!studentScholarshipSelectionComplete"
                color="warning"
              >
                mdi-progress-clock
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <div v-if="tbsScholarship">
          <v-card-text
            :class="stepColor(steps.TBS_SCHOLARSHIP)"
            @click="setStudentCurrentStepDirectly(steps.TBS_SCHOLARSHIP)"
          >
            <v-row>
              <v-col cols="10">Tom and Becky Smith Scholarship</v-col>
              <v-col cols="2">
                <v-icon v-if="tbsScholarshipComplete" color="success">
                  mdi-check
                </v-icon>
                <v-icon v-else-if="!tbsScholarshipComplete" color="warning">
                  mdi-progress-clock
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
        </div>
        <div v-if="itoScholarship">
          <v-card-text
            :class="stepColor(steps.ITO_SCHOLARSHIP)"
            @click="setStudentCurrentStepDirectly(steps.ITO_SCHOLARSHIP)"
          >
            <v-row>
              <v-col cols="10">ITO Scholarship</v-col>
              <v-col cols="2">
                <v-icon v-if="itoScholarshipComplete" color="success">
                  mdi-check
                </v-icon>
                <v-icon v-else-if="!itoScholarshipComplete" color="warning">
                  mdi-progress-clock
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
        </div>
        <div v-if="rmScholarship">
          <v-card-text
            :class="stepColor(steps.RM_SCHOLARSHIP)"
            @click="setStudentCurrentStepDirectly(steps.RM_SCHOLARSHIP)"
          >
            <v-row>
              <v-col cols="10">Return Missionary Scholarship</v-col>
              <v-col cols="2">
                <v-icon v-if="studentRMScholarshipComplete" color="success">
                  mdi-check
                </v-icon>
                <v-icon
                  v-else-if="!studentRMScholarshipComplete"
                  color="warning"
                >
                  mdi-progress-clock
                </v-icon>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
        </div>
        <v-card-text
          :class="stepColor(steps.REVIEW)"
          @click="setStudentCurrentStepDirectly(steps.REVIEW)"
        >
          Review Application
        </v-card-text>
        <v-divider />
      </div>
    </v-card>
  </div>
</template>

<script>
import { STUDENT_STEPS } from "../../constants.js";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: [],
  name: "StudentSteps",
  data: () => ({
    steps: STUDENT_STEPS,
  }),
  computed: {
    ...mapState("applicationStore", [
      "currentStudentStep",
      "studentApplicationDynamicChanges",
      "studentApplication",
    ]),
    ...mapGetters("applicationStore", [
      "studentQualified",
      "studentInformationComplete",
      "itoScholarship",
      "tbsScholarship",
      "rmScholarship",
      "studentExcellenceAgreementComplete",
      "studentScholarshipSelectionComplete",
      "tbsScholarshipComplete",
      "itoScholarshipComplete",
      "studentRMScholarshipComplete",
    ]),
  },
  methods: {
    ...mapActions("applicationStore", ["setStudentCurrentStepDirectly"]),
    stepColor(val) {
      return this.currentStudentStep == val
        ? "secondary font-weight-bold white--text hover"
        : "py-2 hover";
    },
  },
};
</script>

<style scoped>
.steps {
  min-width: 300px;
}

v-card-text {
  font-size: 0.75em;
}

.hover {
  cursor: pointer;
}
</style>
