<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Excellence Agreement </v-card-title>
      <v-card-subtitle>
        Please provide us with the following information.
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that my learning experience grades may have an impact on my eligibility for a scholarship."
          value="1"
          required
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that my proficiency grades must be excellent to receive The Expect Excellence Scholarship."
          value="2"
          required
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that if I receive any scholarship I must be a model student that will serve as an example to all ELC students for all of next semester."
          value="3"
          required
        ></v-checkbox>
      </v-card-text>
      <br />
      <StudentNavigation />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "ExcellenceAgreement",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
    },
  }),
  computed: {
    ...mapState("applicationStore", [
      "studentApplication",
      "studentApplicationDynamicChanges",
    ]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
