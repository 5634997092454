var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{attrs:{"color":"secondary"}},[_c('router-link',{staticClass:"homeLink",attrs:{"to":"/"}},[_c('v-toolbar-title',{staticClass:"title"},[_vm._v(" ELC | Scholarship Application ")])],1),_c('v-spacer'),_c('v-icon',{staticClass:"mr-3",attrs:{"id":"menu-activator","color":"white","large":""}},[_vm._v(" mdi-account-circle-outline ")]),_c('v-menu',{attrs:{"activator":"#menu-activator"}},[_c('v-list',[(!_vm.user)?_c('v-list-item',[_c('v-list-item-title',[_c('span',{on:{"click":function($event){_vm.login().then(({ state, token, user, error }) => {
                  _vm.localStorage.setItem('token', token['authorizationHeader']);
                  _vm.localStorage.setItem('user', user.netId);
                  this.$store.commit('SET_USER', user.netId);
                  this.$store.commit('SET_JWT', token['authorizationHeader']);
                })}}},[_vm._v("Login")])])],1):_vm._e(),(_vm.user)?_c('v-list-item',[_c('v-list-item-title',[_c('span',{on:{"click":function($event){_vm.logout().then(({ state, token, user, error }) => {
                  _vm.localStorage.removeItem(
                    'token',
                    token['authorizationHeader']
                  );
                  _vm.localStorage.removeItem('user', user.netId);
                  this.$store.commit('SET_USER', '');
                  this.$store.commit('SET_JWT', '');
                })}}},[_vm._v("Logout")])])],1):_vm._e()],1)],1)],1),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"color":"success","indeterminate":"","absolute":"","stream":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }