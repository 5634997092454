<template>
  <div>
    <v-card>
      <v-card-title> Edit Bishop Instructions Email </v-card-title>

      <v-card-text>
        This is the confirmation email that students are sent when they
        successfully complete the application and they are applying for the RM
        scholarship.
      </v-card-text>

      <v-card-text>
        The system automatically inserts as the first line of the email
        <br />
        `Hello, [studentFullName]!<br /><br />Thank you for applying for
        scholarships at the ELC.<br /><br />
        Please forward this email to your bishop so they can fill out their
        portion of the application.<br /><br />
        Dear Bishop thank you for being willing to recommend [studentFullName]
        for the Returned Missionary Scholarship to be awarded towards next
        semester's study at the English Language Center <br /><br />
        <br />
        At the end of the email it adds the students Net ID
        <br />
        Please do not include a similar salutation since it is added
        automatically!
      </v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-container fluid>
          <vue-editor
            v-model="bishopInstructionsEmail.email_text"
            :editor-toolbar="customToolbar"
          />
          <br />
          <v-btn color="success" class="mr-4" @click="submit"> save </v-btn>
          <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  name: "BishopInstructionsEmail",
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
      ],
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("adminStore", ["bishopInstructionsEmail"]),
  },
  methods: {
    ...mapActions("adminStore", ["updateBishopInstructionsEmailText"]),
    submit() {
      this.updateBishopInstructionsEmailText(this.bishopInstructionsEmail);
    },
    reset() {
      this.bishopInstructionsEmail.email_text =
        this.bishopInstructionsEmail.email_original;
    },
  },
  async created() {
    // EmailText
  },
};
</script>

<style scoped>
.width {
  width: 80%;
  margin: auto;
}
</style>
