<template>
	<v-card-actions class="mt-n10 pb-5">
		<v-btn
			class="ml-7 stepButton"
			color="primary"
			@click="saveChanges('prev')"
			v-if="firstOrLast !== 'first'"
		>
			Previous
		</v-btn>
		<v-spacer />
		<v-btn
			class="mr-7 stepButton"
			color="primary"
			@click="saveChanges('next')"
			v-if="firstOrLast !== 'last' && firstOrLast !== 'final'"
		>
			Next
		</v-btn>
		<v-btn
			class="mr-7 stepButton"
			color="success"
			@click="saveChanges('next')"
			v-if="firstOrLast === 'last'"
			:disabled="!allBishopStepsCompleted"
		>
			Review
		</v-btn>
		<v-btn
			class="mr-7 stepButton"
			color="success"
			@click="submitApplication()"
			v-if="firstOrLast === 'final'"
			:disabled="!allBishopStepsCompleted"
		>
			submit
		</v-btn>
	</v-card-actions>
</template>

<script>
	import { mapActions, mapState, mapGetters } from "vuex";

	export default {
		name: "bishopNavigation",
		props: ["firstOrLast"],
		computed: {
			...mapState("applicationStore", ["bishopApplication"]),
			...mapGetters("applicationStore", ["allBishopStepsCompleted"]),
		},
		methods: {
			...mapActions("applicationStore", [
				"setBishopCurrentStep",
				"postCreateBishopApplication",
			]),
			saveChanges(step) {
				this.setBishopCurrentStep(step);
			},
			async submitApplication() {
				await this.postCreateBishopApplication().then(
					this.$router.push("/submission")
				);
			},
		},
	};
</script>

<style scoped>
	.stepButton {
		width: 100px;
	}
</style>
