import { render, staticRenderFns } from "./ITOScholarship.vue?vue&type=template&id=3be9d3ba&scoped=true"
import script from "./ITOScholarship.vue?vue&type=script&lang=js"
export * from "./ITOScholarship.vue?vue&type=script&lang=js"
import style0 from "./ITOScholarship.vue?vue&type=style&index=0&id=3be9d3ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be9d3ba",
  null
  
)

export default component.exports