<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title>
        Edit Application Window Dates
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <p>
          Enter the dates for when semesters occur and for when applications are
          accepted. This will change wether students are able to fill out
          applications or not. Dates are stored in the following format.
          ('YYYY-MM-DD').
        </p>
      </v-card-text>
      <v-card-text>
        <!-- You will probably need to update the items once you figure out the connection
          to the database -->
        <v-data-table
          :headers="headers"
          :items="semesters"
          :items-per-page="100"
          disable-sort
          :loading="isLoading"
          loading-text="Loading list of deadlines..."
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Semester
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">New Semester</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            label="Semester Name"
                            v-model="newSemester.semesterName"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-label>Application Open Date</v-label>
                      <v-row>
                        <v-col>
                          <v-date-picker v-model="newSemester.applicationOpen">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                      <v-label>Application Close Date</v-label>
                      <v-row>
                        <v-col>
                          <v-date-picker v-model="newSemester.applicationClose">
                          </v-date-picker>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="newSemesterSave">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.semesterName`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.semesterName"
              @save="save(item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
              persistent
            >
              {{ item.semesterName }}
              <template v-slot:input>
                <v-text-field v-model="item.semesterName"> </v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.applicationOpen`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.applicationsOpen"
              @save="save(item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
              persistent
            >
              {{ item.applicationOpen }}
              <template v-slot:input>
                <v-date-picker v-model="item.applicationOpen"> </v-date-picker>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.applicationClose`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.applicationClose"
              @save="save(item)"
              @cancel="cancel"
              @open="open"
              @close="close"
              large
              persistent
            >
              {{ item.applicationClose }}
              <template v-slot:input>
                <v-date-picker v-model="item.applicationClose"> </v-date-picker>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ManageSemesters",

  data() {
    return {
      headers: [
        {
          text: "Semester Name",
          value: "semesterName",
          width: "50px",
        },
        {
          text: "Application Open Date",
          value: "applicationOpen",
          width: "50px",
        },
        {
          text: "Application Close Date",
          value: "applicationClose",
          width: "50px",
        },
      ],
      dialog: false,
      newSemester: {
        semesterName: "",
        applicationOpen: "",
        applicationClose: "",
      },
    };
  },
  methods: {
    ...mapActions("adminStore", ["updateSemester", "postCreateSemester"]),
    save(item) {
      this.updateSemester(item);
    },
    closeDialog() {
      this.dialog = false;
    },
    close() {},
    cancel() {},

    open() {},

    newSemesterSave() {
      console.log(this.newSemester);
      this.dialog = false;
      if (
        this.newSemester.semesterName != "" &&
        this.newSemester.applicationOpen != "" &&
        this.newSemester.applicationClose != ""
      ) {
        this.postCreateSemester(this.newSemester);
        this.newSemester = {
          semesterName: "",
          applicationOpen: "",
          applicationClose: "",
        };
      }
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("adminStore", ["semesters"]),
  },
};
</script>

<style scoped></style>
