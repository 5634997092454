<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Bishop Information </v-card-title>
      <v-card-subtitle>
        Please provide us with your contact information.
      </v-card-subtitle>
      <v-card-text class="p-0">
        <v-text-field
          class="mx-5"
          :label="bishopNameMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.bishopName"
          readonly
        />
        <v-text-field
          class="mx-5"
          :label="phoneNumberMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.phoneNumber"
          readonly
        />
        <v-text-field
          class="mx-5"
          :label="emailMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.email"
          readonly
        />
        <v-text-field
          class="mx-5"
          :label="unitNameMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.unitName"
          readonly
        />
        <v-text-field
          class="mx-5"
          :label="unitIDMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.unitID"
          readonly
        />
        <br />
      </v-card-text>

      <v-card-title> Student Information </v-card-title>
      <v-card-subtitle>
        Please verify, add, or update the student information. For the student
        ID instructions were provided on the form you received from the student.
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          class="mx-5"
          :label="studentNameMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.studentName"
          readonly
        />
        <v-text-field
          class="mx-5"
          :label="studentNetIDMessage"
          type="text"
          outlined
          dense
          v-model="bishopApplication.studentNetID"
          readonly
        />
        <br />
      </v-card-text>
      <v-card-title> Edit Recommendation Information </v-card-title>
      <v-card-subtitle>
        Please let us know what year/semester you are applying to attend
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="The membership record for this individual currently is in our ward/branch records"
          value="1"
          readonly
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="This individual regularly engages in Sunday worship and other meetings"
          value="2"
          readonly
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="This individual meets standards of Gospel living and service that are reasonable to expect from those who have had opportunity to serve a full-time mission for The Church of Jesus Christ of Latter-day Saints"
          value="3"
          readonly
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="I have discussed with this applicant his or her full-time mission experiences of service and testimony building"
          value="4"
          readonly
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="I have discussed with this applicant their future study and professional goals and can affirm that this scholarship would help them reach those goals"
          value="5"
          readonly
        ></v-checkbox>
        <v-label
          >Please include other details related to this individual’s character,
          church service or circumstance that you feel would be relevant for the
          selection committee to consider</v-label
        >
        <v-textarea
          outlined
          label="Extra details"
          value=""
          v-model="bishopApplication.additionalDetail"
          readonly
        ></v-textarea>
        <br />
      </v-card-text>

      <BishopNavigation :firstOrLast="'final'" />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import BishopNavigation from "@/components/bishop/BishopNavigation.vue";
export default {
  name: "BishopReview",
  components: {
    BishopNavigation,
  },
  data: () => ({
    bishopNameMessage: "Bishop Name*",
    phoneNumberMessage: "Phone Number*",
    emailMessage: "Email Address*",
    unitNameMessage: "Unit Name*",
    unitIDMessage: "Unit ID*",
    studentNameMessage: "Student Full Name*",
    studentNetIDMessage: "Student BYU ID (9 digits)*",
  }),
  computed: {
    ...mapState("applicationStore", ["bishopApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

::v-deep .v-card__text {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0;
}

::v-deep .v-card__title {
  margin-top: 0px;
  padding-top: 0px;
}
::v-deep .v-card__subtitle {
  padding-bottom: 0.8rem;
}
</style>
