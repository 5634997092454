<template>
  <div>
    <v-card class="steps" outlined>
      <v-card-text
        :class="stepColor(steps.BISHOP_INFORMATION)"
        @click="setBishopCurrentStepDirectly(steps.BISHOP_INFORMATION)"
      >
        <v-row>
          <v-col cols="10"> Step 1: Bishop Information </v-col>
          <v-col cols="2">
            <v-icon v-if="bishopInfoComplete" color="success">
              mdi-check
            </v-icon>
            <v-icon v-else-if="!bishopInfoComplete" color="warning">
              mdi-progress-clock
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.STUDENT_INFORMATION)"
        @click="setBishopCurrentStepDirectly(steps.STUDENT_INFORMATION)"
      >
        <v-row>
          <v-col cols="10"> Step 2: Student Information </v-col>
          <v-col cols="2">
            <v-icon v-if="bishopStudentComplete" color="success">
              mdi-check
            </v-icon>
            <v-icon v-else-if="!bishopStudentComplete" color="warning">
              mdi-progress-clock
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.RECOMMENDATION)"
        @click="setBishopCurrentStepDirectly(steps.RECOMMENDATION)"
      >
        <v-row>
          <v-col cols="10"> Step 3: Recommendation </v-col>
          <v-col cols="2">
            <v-icon v-if="bishopRecommendationComplete" color="success">
              mdi-check
            </v-icon>
            <v-icon v-else-if="!bishopRecommendationComplete" color="warning">
              mdi-progress-clock
            </v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.REVIEW)"
        @click="setBishopCurrentStepDirectly(steps.REVIEW)"
      >
        Review Application
      </v-card-text>
      <v-divider />
    </v-card>
  </div>
</template>

<script>
import { BISHOP_STEPS } from "../../constants.js";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  props: [],
  name: "BishopSteps",
  data: () => ({
    steps: BISHOP_STEPS,
  }),
  computed: {
    ...mapState("applicationStore", [
      "bishopStepsCompleted",
      "currentBishopStep",
    ]),
    ...mapGetters("applicationStore", [
      "bishopInfoComplete",
      "bishopStudentComplete",
      "bishopRecommendationComplete",
    ]),
  },
  methods: {
    ...mapActions("applicationStore", ["setBishopCurrentStepDirectly"]),
    stepColor(val) {
      return this.currentBishopStep == val
        ? "secondary font-weight-bold white--text hover"
        : "py-2 hover";
    },
  },
};
</script>

<style scoped>
.steps {
  min-width: 300px;
}

v-card-text {
  font-size: 0.75em;
}

.hover {
  cursor: pointer;
}
</style>
