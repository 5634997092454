import { render, staticRenderFns } from "./StudentNavigation.vue?vue&type=template&id=6334c3db&scoped=true"
import script from "./StudentNavigation.vue?vue&type=script&lang=js"
export * from "./StudentNavigation.vue?vue&type=script&lang=js"
import style0 from "./StudentNavigation.vue?vue&type=style&index=0&id=6334c3db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6334c3db",
  null
  
)

export default component.exports