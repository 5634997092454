<template>
  <div>
    <v-card>
      <v-card-title> Edit Student Email </v-card-title>

      <v-card-text>
        This is the confirmation email that students are sent when they
        successfully complete the application.
      </v-card-text>

      <v-card-text>
        The system automatically inserts as the first line of the email
        <br />
        `Hello, [Student Full Name]! Thank you for applying for scholarships at
        the ELC.
        <br />
        Please do not include a similar salutation since it is added
        automatically!
      </v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-container fluid>
          <vue-editor
            v-model="studentEmail.email_text"
            :editor-toolbar="customToolbar"
          />
          <br />
          <v-btn color="success" class="mr-4" @click="submit"> save </v-btn>
          <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { VueEditor } from "vue2-editor";
export default {
  name: "StudentEmail",
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
      ],
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("adminStore", ["studentEmail"]),
  },
  methods: {
    ...mapActions("adminStore", ["updateStudentEmail"]),
    submit() {
      this.updateStudentEmail(this.studentEmail);
    },
    reset() {
      this.studentEmail.email_text = this.studentEmail.email_original;
    },
  },
  async created() {
    // EmailText
  },
};
</script>

<style scoped>
.width {
  width: 80%;
  margin: auto;
}
</style>
