<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Student Information </v-card-title>
      <v-card-subtitle>
        Please verify, add, or update the student information. For the student
        ID instructions were provided on the form you received from the student.
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          class="mx-5"
          :label="studentNameMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.studentName"
        />
        <v-text-field
          class="mx-5"
          :label="studentNetIDMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required, rules.length]"
          v-model="bishopApplication.studentNetID"
        />
        <br />
      </v-card-text>

      <BishopNavigation />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import BishopNavigation from "@/components/bishop/BishopNavigation.vue";
export default {
  name: "BishopStudentInformation",
  components: {
    BishopNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      length: (value) => value.length == 9 || "The length must be 9 digits",
    },
    studentNameMessage: "Student Full Name*",
    studentNetIDMessage: "Student BYU ID (9 digits)*",
  }),
  computed: {
    ...mapState("applicationStore", ["bishopApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
