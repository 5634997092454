<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Bishop Information </v-card-title>
      <v-card-subtitle>
        Please provide us with your contact information.
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          class="mx-5"
          :label="bishopNameMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.bishopName"
        />
        <v-text-field
          class="mx-5"
          :label="phoneNumberMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.phoneNumber"
        />
        <v-text-field
          class="mx-5"
          :label="emailMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.email"
        />
        <v-text-field
          class="mx-5"
          :label="unitNameMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.unitName"
        />
        <v-text-field
          class="mx-5"
          :label="unitIDMessage"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="bishopApplication.unitID"
        />
        <br />
      </v-card-text>

      <BishopNavigation :firstOrLast="'first'" />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import BishopNavigation from "@/components/bishop/BishopNavigation.vue";
export default {
  name: "BishopInfo",
  components: {
    BishopNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
    },
    bishopNameMessage: "Bishop Name*",
    phoneNumberMessage: "Phone Number*",
    emailMessage: "Email Address*",
    unitNameMessage: "Unit Name*",
    unitIDMessage: "Unit ID*",
  }),
  computed: {
    ...mapState("applicationStore", ["bishopApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
