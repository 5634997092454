import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import BishopPage from "@/views/BishopPage.vue";
import StudentPage from "@/views/StudentPage.vue";
import AdminPage from "@/views/AdminPage.vue";

import SubmissionConfirmation from "../components/shared/SubmissionConfirmation.vue";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: LandingPage,
	},
	{
		path: "/bishop",
		name: "Bishop",
		component: BishopPage,
	},
	{
		path: "/student",
		name: "Student",
		component: StudentPage,
	},
	{
		path: "/admin",
		name: "Admin",
		component: AdminPage,
	},
	{
		path: "/submission",
		name: "Submission",
		component: SubmissionConfirmation,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
