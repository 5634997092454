import axios from "axios";
const baseUrl = "https://elcscholarship.byu.edu/api/";
// const baseUrl = "http://localhost:3001/";

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	const user = localStorage.getItem("user");
	config.headers.authorization = token;
	config.headers.current_user = user;
	return config;
});

export default {
	data() {
		return {};
	},
	// API calls go here
	async getAllAdmins() {
		return await axios.get(baseUrl + `administrators/`);
	},

	async postCreateAdmin(netid) {
		return await axios.post(baseUrl + `administrators/?netid=${netid}`);
	},

	async deleteAdmin(netid) {
		return await axios.delete(baseUrl + `administrators/?netid=${netid}`);
	},

	async getSemesters() {
		return await axios.get(baseUrl + `semester`);
	},

	async updateSemester(semester) {
		return await axios.post(baseUrl + `semesterAdmin/update`, { semester });
	},
	async getAllInfoSemesters() {
		return await axios.get(baseUrl + `semesterAdmin/applications`);
	},
	async updateDownloadInfoSemesters(semester) {
		return await axios.post(baseUrl + `semesterAdmin/download`, {
			semester,
		});
	},
	async createSemester(semester) {
		return await axios.post(baseUrl + `semesterAdmin`, { semester });
	},

	async getStudentEmail(email) {
		return await axios.get(baseUrl + `studentEmail`, { email });
	},
	async updateStudentEmail(email) {
		return await axios.post(baseUrl + `studentEmail`, { email });
	},
	async getBishopInstructionsEmailText(email) {
		return await axios.get(baseUrl + `studentEmail/bishopInstruction`, {
			email,
		});
	},
	async updateBishopInstructionsEmailText(email) {
		return await axios.post(baseUrl + `studentEmail/bishopInstruction`, {
			email,
		});
	},
	async getBishopEmail(email) {
		return await axios.get(baseUrl + `bishopEmail`, { email });
	},
	async updateBishopEmail(email) {
		return await axios.post(baseUrl + `bishopEmail`, { email });
	},
};
