<template>
  <v-container>
    <v-row class="mt-5">
      <v-col xs="12" sm="6" md="4" class="mr-3">
        <div class="sidebar">
          <BishopSteps />
        </div>
      </v-col>
      <v-col xs="12" sm="6" md="6" elevation="25">
        <BishopInfo v-if="currentBishopStep == steps.BISHOP_INFORMATION" />
        <BishopStudentInformation
          v-if="currentBishopStep == steps.STUDENT_INFORMATION"
        />
        <BishopRecommendation
          v-if="currentBishopStep == steps.RECOMMENDATION"
        />
        <BishopReview v-if="currentBishopStep == steps.REVIEW" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BISHOP_STEPS } from "@/constants.js";
import BishopSteps from "@/components/bishop/BishopSteps.vue";
import { mapState } from "vuex";
import BishopInfo from "@/components/bishop/steps/BishopInformation.vue";
import BishopStudentInformation from "@/components/bishop/steps/BishopStudentInformation.vue";
import BishopRecommendation from "@/components/bishop/steps/BishopRecommendation.vue";
import BishopReview from "@/components/bishop/steps/BishopReview.vue";

export default {
  name: "BishopPage",
  components: {
    BishopSteps,
    BishopInfo,
    BishopStudentInformation,
    BishopRecommendation,
    BishopReview,
  },
  data() {
    return {
      steps: BISHOP_STEPS,
      dialog: false,
    };
  },
  computed: {
    ...mapState("applicationStore", ["currentBishopStep"]),
  },
  methods: {},
  async created() {},
  watch: {
    currentBishopStep(value) {
      localStorage.bishopStep = value;
    },
  },
};
</script>

<style scoped>
.docsDialog {
  height: 600px;
}
</style>
