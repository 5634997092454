<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Tom and Becky Smith Scholarship </v-card-title>
      <v-card-subtitle>
        Describe why you are studying English and how this scholarship will help
        you reach your goals. (200-250 words)
      </v-card-subtitle>
      <v-card-text>
        <v-textarea
          outlined
          label="Essay"
          class="mx-5"
          value=""
          auto-grow
          :rules="[rules.maxLength]"
          v-model="studentApplication.tomBeckySmithEssay"
        ></v-textarea>
      </v-card-text>

      <StudentNavigation />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "TBSScholarship",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      maxLength: (value) =>
        value.length <= 2500 ||
        "Length of essay may not exceed 2500 characters",
    },
  }),
  computed: {
    ...mapState("applicationStore", ["studentApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
