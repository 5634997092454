<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Edit Recommendation Information </v-card-title>
      <v-card-subtitle>
        Please let us know what year/semester you are applying to attend
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="The membership record for this individual currently is in our ward/branch records."
          value="1"
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="This individual regularly engages in Sunday worship and other meetings."
          value="2"
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="This individual meets standards of gospel living and service that are reasonable to expect from those who have had opportunity to serve a full-time mission for The Church of Jesus Christ of Latter-day Saints."
          value="3"
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="bishopApplication.bishop_recommendation"
          label="I have met with the individual and discussed with this applicant his or her full-time mission experiences of service and testimony building."
          value="4"
        ></v-checkbox>
        <v-label
          >Please include other details related to this individual`s character,
          church service or circumstance that you feel would be relevant for the
          selection committee to consider</v-label
        >
        <v-textarea
          outlined
          label="Extra details"
          value=""
          auto-grow
          :rules="[rules.maxLength]"
          v-model="bishopApplication.additionalDetail"
        ></v-textarea>
        <br />
      </v-card-text>

      <BishopNavigation :firstOrLast="'last'" />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import BishopNavigation from "@/components/bishop/BishopNavigation.vue";
export default {
  name: "BishopRecommendation",
  components: {
    BishopNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      maxLength: (value) =>
        value.length <= 3000 ||
        "Length of essay may not exceed 3000 characters",
    },
    bishopNameMessage: "Bishop Name*",
    phoneNumberMessage: "Phone Number*",
    emailMessage: "Email Address*",
    unitNameMessage: "Unit Name*",
    unitIDMessage: "Unit ID*",
  }),
  computed: {
    ...mapState("applicationStore", ["bishopApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
