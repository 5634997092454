<template>
	<div class="landingCenter">
		<br />
		<v-container class="mx-5">
			<v-row class="my-5">
				<v-card-text class="homePageText titleHome"
					>Welcome to the English Language Center</v-card-text
				>
				<v-card-text class="homePageText" v-if="currentSemester"
					>Please click below to fill out the application as a
					student.</v-card-text
				>
				<v-btn
					v-if="currentSemester"
					class="homepageButtons"
					to="/student"
					>Student</v-btn
				>
				<v-card-text class="homePageText" v-if="currentSemester"
					>Please click below to fill out the application as a
					bishop.</v-card-text
				>
				<v-btn
					v-if="currentSemester"
					class="homepageButtons"
					to="/bishop"
					>Bishop</v-btn
				>

				<v-card-text class="homePageText" v-if="!currentSemester">
					Scholarships are currently closed. Please revist the site
					when scholarship applications open up again.
				</v-card-text>
			</v-row>
			<v-row class="my-5" v-if="this.administrator">
				<v-card-text class="homePageText"
					>The button below takes you to the administrative side of
					the application.</v-card-text
				>
				<v-btn class="homepageButtons" to="/admin">Admin</v-btn>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	export default {
		name: "LandingPage",
		data() {
			return {};
		},
		computed: {
			...mapState("adminStore", ["administrator"]),
			...mapState("applicationStore", ["currentSemester"]),
		},
		methods: {
			...mapActions("applicationStore", ["getCurrentSemester"]),
		},
		components: {},
		async created() {
			await this.getCurrentSemester().catch((e) => console.error(e));
		},
	};
</script>

<style scoped>
	.homepageButtons {
		background-color: #0062b8 !important;
		color: white !important;
		margin: auto;
	}

	.homePageText {
		text-align: center;
		font-size: 1.2rem;
	}

	.titleHome {
		font-weight: 900;
		font-size: 2rem;
		line-height: 2rem;
	}
	.landingCenter {
		display: flex;
		justify-content: center;
	}
</style>
