<template>
	<div>
		<div v-if="studentQualified">
			<v-card-actions class="mt-n10 pb-5">
				<v-btn
					class="ml-7 stepButton"
					color="primary"
					@click="saveChanges('prev')"
					v-if="firstOrLast !== 'first'"
				>
					Previous
				</v-btn>
				<v-spacer />
				<v-btn
					class="mr-7 stepButton"
					color="primary"
					@click="saveChanges('next')"
					v-if="firstOrLast !== 'last' && firstOrLast !== 'final'"
				>
					Next
				</v-btn>
				<v-btn
					class="mr-7 stepButton"
					color="success"
					@click="saveChanges('next')"
					v-if="firstOrLast === 'last'"
					:disabled="!allStudentStepsCompleted"
				>
					Review
				</v-btn>
				<v-btn
					class="mr-7 stepButton"
					color="success"
					@click="submitApplication()"
					v-if="firstOrLast === 'final'"
					:disabled="!allStudentStepsCompleted"
				>
					submit
				</v-btn>
			</v-card-actions>
		</div>
		<div v-if="!studentQualified">
			<v-subheader class="mb-10"
				>You are currently not qualified for a scholarship. Review your
				answers above if you believe that is not the case.</v-subheader
			>

			<v-card-actions class="mt-n10 pb-5">
				<v-btn
					class="ml-7 stepButton"
					color="primary"
					@click="routeHome"
					v-if="firstOrLast == 'first'"
				>
					Close
				</v-btn>
			</v-card-actions>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapState, mapGetters } from "vuex";

	export default {
		name: "studentNavigation",
		props: ["firstOrLast"],
		computed: {
			...mapState("applicationStore", ["studentApplication"]),
			...mapGetters("applicationStore", [
				"allStudentStepsCompleted",
				"studentQualified",
			]),
		},
		methods: {
			...mapActions("applicationStore", [
				"setStudentCurrentStep",
				"studentApplicationDynamicChanges",
				"postCreateStudentApplication",
			]),
			saveChanges(step) {
				this.setStudentCurrentStep(step);
			},
			routeHome() {
				this.$router.push("/submission");
			},
			submitApplication() {
				this.postCreateStudentApplication();
				this.routeHome();
			},
		},
	};
</script>

<style scoped>
	.stepButton {
		width: 100px;
	}
</style>
