<template>
  <v-app>
    <Topbar class="do_not_print_this_feature" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Topbar from "@/components/shared/Topbar";
import { AuthenticationObserver } from "@byuweb/browser-oauth";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Topbar,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("adminStore", ["getAllAdmins"]),
    ...mapState({
      user: (state) => state.user,
      jwtToken: (state) => state.jwtToken,
    }),
  },
  mutations: {},
  created() {
    return {
      observer: new AuthenticationObserver(({ token, user, error }) => {
        // React to the change in state
        if (error) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        } else if (token && user) {
          this.$store.commit("SET_USER", user.netId);
          this.$store.commit("SET_JWT", token["authorizationHeader"]);
          localStorage.setItem("token", token["authorizationHeader"]);
          localStorage.setItem("user", user.netId);
          this.getAllAdmins();
        }
      }),
    };
  },
};
</script>
<style scoped>
@media print {
  .do_not_print_this_feature {
    display: none;
  }
}
</style>
