<!-- eslint-disable -->
<template>
	<div>
		<v-card class="mb-5" elevation="25" outlined>
			<v-card-title>
				Manage Applications
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-subtitle
				>Each accordion will expand to show several options. Each option
				will have a csv download for that specific semester.
			</v-card-subtitle>
			<v-card-subtitle
				>Also If a button does not download an excel spreadsheet it
				could mean there is no data to download. Please do not click the
				button multiple times in a row. If you aren't getting the result
				you want please wait 5 secs before clicking it again. Do this a
				couple times and if you believe there should be data please
				contact a developer to figure out the issue.
			</v-card-subtitle>
			<v-expansion-panels>
				<v-expansion-panel
					v-for="allInfoSemester in allInfoSemesters"
					:key="allInfoSemester.id"
				>
					<v-expansion-panel-header>
						{{ allInfoSemester.semesterName }}
					</v-expansion-panel-header>
					<v-expansion-panel-content class="mb-2">
						<v-row no-gutters>
							<v-col sm="12" md="6" lg="6" class="mb-2">
								<v-btn class="primary">
									<downloadExcel
										class=""
										:data="
											allInfoSemester.student_application.filter(
												(x) =>
													x.tomBeckySmithEssay
														? x.tomBeckySmithEssay
																.length >= 1
														: false
											)
										"
										:fields="tbsFields"
										:name="
											allInfoSemester.semesterName +
											' TBS.xls'
										"
									>
										Tom Becky Smith
									</downloadExcel>
								</v-btn>
							</v-col>
							<v-col sm="12" md="6" lg="6" class="">
								<v-btn
									class="primary"
									@click="
										markApplicationsDownloaded(
											allInfoSemester
										)
									"
								>
									<downloadExcel
										class=""
										:data="
											allInfoSemester.student_application.filter(
												(x) => x.downloaded == 0
											)
										"
										:name="
											allInfoSemester.semesterName +
											' Newest Applications.xls'
										"
										:fields="allFields"
										:escapeCsv="false"
										:before-generate="
											pullInfoBeforeDownload
										"
									>
										New Applications
									</downloadExcel>
								</v-btn>
							</v-col>
							<v-col sm="12" md="6" lg="6" class="mb-2">
								<v-btn class="primary">
									<downloadExcel
										class=""
										:data="
											allInfoSemester.student_application.filter(
												(x) =>
													x.itoEssay
														? x.itoEssay.length >= 1
														: false
											)
										"
										:fields="itoFields"
										:separator-excel="true"
										:name="
											allInfoSemester.semesterName +
											' Ito.xls'
										"
									>
										Ito
									</downloadExcel>
								</v-btn>
							</v-col>
							<v-col sm="12" md="6" lg="6" class="mb-2">
								<v-btn class="primary">
									<downloadExcel
										class=""
										:data="
											allInfoSemester.student_application
										"
										:name="
											allInfoSemester.semesterName +
											' ALL Applications.xls'
										"
										:fields="allFields"
										:escapeCsv="false"
									>
										All Applications
									</downloadExcel>
								</v-btn>
							</v-col>

							<v-col sm="12" md="6" lg="6" class="mb-2">
								<v-btn class="primary">
									<downloadExcel
										class=""
										:data="
											allInfoSemester.student_application.filter(
												(x) =>
													x.return_missionary_scholarship
											)
										"
										:name="
											allInfoSemester.semesterName +
											' RM.xls'
										"
										:fields="rmFields"
										:escapeCsv="false"
									>
										RM Scholarship
									</downloadExcel>
								</v-btn>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex";
	export default {
		name: "ManageApplications",
		components: {},
		data() {
			return {
				itoFields: {
					ID: "id",
					"Full Name": "studentFullName",
					NetID: "studentNetID",
					"ELC Start": "firstSemesterAtELC",
					Email: "email",
					"Academic Level": "currentLevel",
					"Studying Goals": "studyingGoals",
					"ITO Essay": "itoEssay",
				},
				tbsFields: {
					ID: "id",
					"Full Name": "studentFullName",
					NetID: "studentNetID",
					"ELC Start": "firstSemesterAtELC",
					Email: "email",
					"Academic Level": "currentLevel",
					"Studying Goals": "studyingGoals",
					"TBS Essay": "tomBeckySmithEssay",
				},
				rmFields: {
					ID: "id",
					"Full Name": "studentFullName",
					NetID: "studentNetID",
					"ELC Start": "firstSemesterAtELC",
					Email: "email",
					"Studying Goals": "studyingGoals",
					"Academic Level": "currentLevel",
					"RM Essay": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.returnMissionaryEssay;
						},
					},
					"Marriage Status": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.marriageStatus;
						},
					},
					Undergrad: {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.undergrad;
						},
					},
					Graduate: {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.graduate;
						},
					},
					"TOEFL Score": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.toeflScore;
						},
					},
					"Name Of Mission": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.nameOfMission;
						},
					},
					"Mission Start": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.startDateMission;
						},
					},
					"Mission End": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.endDateMission;
						},
					},
					"Bishop Name": {
						field: "bishopApplication",
						callback: (value) => {
							return value.bishopName;
						},
					},
					"Bishop Phone": {
						field: "bishopApplication",
						callback: (value) => {
							return value.phoneNumber;
						},
					},
					"Bishop Email": {
						field: "bishopApplication",
						callback: (value) => {
							return value.email;
						},
					},
					"Unit Name": {
						field: "bishopApplication",
						callback: (value) => {
							return value.unitName;
						},
					},
					"Unit ID": {
						field: "bishopApplication",
						callback: (value) => {
							return value.unitID;
						},
					},
					"Bishop Detail": {
						field: "bishopApplication",
						callback: (value) => {
							return value.additionalDetail;
						},
					},
					"Bishop Recommendation": {
						field: "bishopApplication",
						callback: (value) => {
							if (value.bishop_recommendation) {
								var string = `"`;
								value.bishop_recommendation.forEach((el) => {
									string =
										string + `${el.recommendationInfo}\n`;
								});
								string = string + `"`;
							}
							return string;
						},
					},
				},
				allFields: {
					AppID: "id",
					"Full Name": "studentFullName",
					ByuID: "studentNetID",
					"ELC Start": "firstSemesterAtELC",
					Email: "email",
					"Academic Level": "currentLevel",
					"Studying Goals": "studyingGoals",
					"ITO Essay": "itoEssay",
					"TBS Essay": "tomBeckySmithEssay",
					"RM Essay": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.returnMissionaryEssay;
						},
					},
					"Marriage Status": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.marriageStatus;
						},
					},
					Undergrad: {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.undergrad;
						},
					},
					Graduate: {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.graduate;
						},
					},
					"TOEFL Score": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.toeflScore;
						},
					},
					"Name Of Mission": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.nameOfMission;
						},
					},
					"Mission Start": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.startDateMission;
						},
					},
					"Mission End": {
						field: "return_missionary_scholarship",
						callback: (value) => {
							return value.endDateMission;
						},
					},
					"Bishop Name": {
						field: "bishopApplication",
						callback: (value) => {
							return value.bishopName;
						},
					},
					"Bishop Phone": {
						field: "bishopApplication",
						callback: (value) => {
							return value.phoneNumber;
						},
					},
					"Bishop Email": {
						field: "bishopApplication",
						callback: (value) => {
							return value.email;
						},
					},
					"Unit Name": {
						field: "bishopApplication",
						callback: (value) => {
							return value.unitName;
						},
					},
					"Unit ID": {
						field: "bishopApplication",
						callback: (value) => {
							return value.unitID;
						},
					},
					"Bishop Detail": {
						field: "bishopApplication",
						callback: (value) => {
							return value.additionalDetail;
						},
					},
					"Bishop Recommendation": {
						field: "bishopApplication",
						callback: (value) => {
							if (value.bishop_recommendation) {
								var string = `"`;
								value.bishop_recommendation.forEach((el) => {
									string =
										string + `${el.recommendationInfo}\n`;
								});
								string = string + `"`;
							}
							return string;
						},
					},
				},
			};
		},
		computed: {
			...mapState({
				isLoading: (state) => state.isLoading,
			}),
			...mapState("adminStore", ["allInfoSemesters"]),
		},
		methods: {
			...mapActions("adminStore", [
				"getAllInfoSemesters",
				"updateDownloadInfoSemesters",
			]),
			markApplicationsDownloaded(Semester) {
				this.updateDownloadInfoSemesters(Semester).catch((e) =>
					console.error(e)
				);
			},
			pullInfoBeforeDownload() {
				this.getAllInfoSemesters().catch((e) => console.error(e));
			},
		},
		async created() {},
	};
</script>
<style scoped></style>
