<template>
  <span>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Student Qualifications </v-card-title>
      <v-card-subtitle>
        Please answer the following questions to see if you qualify for a
        scholarship.
      </v-card-subtitle>
      <v-card-text>
        <v-label>Are you currently enrolled as a student at the ELC?</v-label>
        <v-radio-group
          v-model="studentApplicationDynamicChanges.elcStudentStatus"
        >
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
        <v-label
          >Students leaving for or returning from approved "study" vacations are
          NOT eligible to apply for this scholarship. Are you applying for or
          returning from an approved vacation?</v-label
        >
        <v-radio-group
          v-model="studentApplicationDynamicChanges.elcStudyAbroadStatus"
        >
          <v-radio label="No" :value="true"></v-radio>
          <v-radio label="Yes" :value="false"></v-radio>
        </v-radio-group>
      </v-card-text>

      <StudentNavigation :firstOrLast="'first'" />
    </v-card>
  </span>
</template>

<script>
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";

export default {
  name: "StudentQualification",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
    },
    bishopNameMessage: "Bishop Name*",
    phoneNumberMessage: "Phone Number*",
    emailMessage: "Email Address*",
    unitNameMessage: "Unit Name*",
    unitIDMessage: "Unit ID*",
  }),
  computed: {
    ...mapState("applicationStore", ["studentApplicationDynamicChanges"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
