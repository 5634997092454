<!-- eslint-disable -->
<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title>
        Manage Admins
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="admins"
          :items-per-page="100"
          :search="search"
          :loading="isLoading"
          loading-text="Loading list of Admins..."
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Admin
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">New Admin</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-text-field
                            label="New User BYU NETID"
                            v-model="newAdmin"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="error" class="ml-2" @click="deleteItem(item.netID)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ManageAdmins",
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Net ID",
          value: "netID",
          align: "start",
          width: "130",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      dialog: false,
      newAdmin: "",
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("adminStore", ["admins"]),
  },
  methods: {
    ...mapActions("adminStore", ["postCreateAdmin", "deleteAdmin"]),
    close() {
      this.dialog = false;
    },
    save() {
      this.dialog = false;
      if (this.newAdmin != "") {
        this.postCreateAdmin(this.newAdmin);
        this.newAdmin = "";
      }
    },
    deleteItem(item) {
      this.deleteAdmin(item);
      const projectIndex = this.admins.findIndex((p) => p.netID === item);
      this.admins.splice(projectIndex, 1);
    },
  },
  async created() {},
};
</script>
<style scoped></style>
