<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Review Application </v-card-title>
      <v-card-title> Student Information </v-card-title>
      <v-card-subtitle>
        Please provide us with the following information.
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          class="mx-5"
          label="Student Full Name*"
          type="text"
          outlined
          dense
          v-model="studentApplication.studentFullName"
          readonly
        />
        <v-text-field
          class="mx-5"
          label="BYU ID Number(9 Digits)*"
          type="number"
          outlined
          dense
          readonly
          v-model="studentApplication.studentNetID"
        />
        <v-text-field
          class="mx-5"
          label="First Semester at the ELC*"
          type="text"
          outlined
          dense
          readonly
          v-model="studentApplication.firstSemesterAtELC"
        />
        <v-text-field
          class="mx-5"
          :label="emailMessage"
          type="email"
          outlined
          dense
          readonly
          v-model="studentApplication.email"
        />
        <v-label>What level are you currently enrolled in at the ELC.</v-label>
        <v-select
          class="mx-5"
          :items="languageLevels"
          dense
          outlined
          readonly
          v-model="studentApplication.currentLevel"
          required
        ></v-select>
        <v-label
          >Briefly but with specific details describe your goals for studying
          English</v-label
        >
        <v-textarea
          outlined
          class="mx-5"
          label="Essay"
          value=""
          readonly
          auto-grow
          v-model="studentApplication.studyingGoals"
        ></v-textarea>
        <br />
      </v-card-text>
      <v-card-title> Excellence Agreement </v-card-title>
      <v-card-subtitle>
        Please provide us with the following information.
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that my learning experience grades may have an impact on my eligibility for a scholarship."
          value="1"
          readonly
          required
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that my proficiency grades must be excellent to receive The Expect Excellence Scholarship."
          value="2"
          readonly
          required
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.elcExcellenceAgreement"
          label="I understand that if I receive any scholarship I must be a model student that will serve as an example to all ELC students for all of next semester."
          value="3"
          readonly
          required
        ></v-checkbox>
      </v-card-text>
      <v-card-title> Scholarship Selection </v-card-title>
      <v-card-subtitle>
        Please select which scholarships you would like to apply for (check all
        that apply):
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          v-if="
            studentApplication.currentLevel == 'Foundations Prep' ||
            studentApplication.currentLevel == 'Foundations A' ||
            studentApplication.currentLevel == 'Foundations B' ||
            studentApplication.currentLevel == 'Foundations C'
          "
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.tbsScholarship"
          label="Tom and Becky Smith Scholarship (must be currently studying in Foundations Prep, Foundations A, Foundations B, or Foundations C)"
          value="1"
          readonly
          required
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.itoScholarship"
          label="Ito Scholarship"
          value="1"
          readonly
          required
        ></v-checkbox>
        <v-checkbox
          v-if="
            studentApplication.currentLevel == 'Academic A' ||
            studentApplication.currentLevel == 'Academic B' ||
            studentApplication.currentLevel == 'University Prep'
          "
          class="m-0 p-0"
          v-model="studentApplicationDynamicChanges.rmScholarship"
          label="Returned Missionary Scholarship"
          value="1"
          readonly
          required
        ></v-checkbox>
      </v-card-text>
      <div v-if="tbsScholarship">
        <v-card-title> Tom and Becky Smith Scholarship </v-card-title>
        <v-card-subtitle>
          Describe why you are studying English and how this scholarship will
          help you reach your goals. (200-250 words)
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            outlined
            label="Essay"
            class="mx-5"
            value=""
            readonly
            auto-grow
            v-model="studentApplication.tomBeckySmithEssay"
          ></v-textarea>
        </v-card-text>
      </div>
      <div v-if="itoScholarship">
        <v-card-title> Ito Scholarship </v-card-title>
        <v-card-subtitle>
          Describe a set of challenges or difficult circumstances that you have
          overcome while learning English. These can be difficulties you faced
          before coming to the ELC or while at the ELC. In addition to
          describing these difficulties, describe your efforts to contribute
          positively to the community of learners that you belong to. Use
          specific details and examples to support your answer. Your response
          should be between 200-250 words.
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            readonly
            outlined
            label="Essay"
            class="mx-5"
            value=""
            auto-grow
            v-model="studentApplication.itoEssay"
          ></v-textarea>
          <v-label
            >I understand that the Ito Scholarship is a half-tuition
            scholarship.</v-label
          >
          <v-radio-group
            readonly
            v-model="studentApplicationDynamicChanges.itoAgreement"
          >
            <v-radio
              label="I understand that the Ito Scholarship is a half-tuition scholarship."
              readonly
              :value="true"
            ></v-radio>
            <v-radio
              label="Never mind. I'm not interested in this scholarship."
              readonly
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </div>
      <div v-if="rmScholarship">
        <v-card-title> Returned Missionary Scholarship </v-card-title>
        <v-card-subtitle>
          Please provide us with the following information.
        </v-card-subtitle>
        <v-card-text>
          <v-label>Please select your marital status below.</v-label>
          <v-radio-group
            readonly
            v-model="
              studentApplication.returnMissionaryScholarship.marriageStatus
            "
          >
            <v-radio label="Married" readonly value="married"></v-radio>
            <v-radio label="Single" readonly value="single"></v-radio>
          </v-radio-group>
          <v-label>Please check all that apply to you</v-label>
          <v-checkbox
            readonly
            class="m-0 p-0"
            v-model="
              studentApplication.returnMissionaryScholarship.schoolingBackground
            "
            label="I have graduated from highschool. (required)"
            value="highschool"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="m-0 p-0"
            v-model="
              studentApplication.returnMissionaryScholarship.schoolingBackground
            "
            label="I have an undergraduate degree."
            value="undergrad"
          ></v-checkbox>
          <v-checkbox
            readonly
            class="m-0 p-0"
            v-model="
              studentApplication.returnMissionaryScholarship.schoolingBackground
            "
            label="I have a graduate degree."
            value="graduate"
          ></v-checkbox>
          <div
            v-if="
              studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
                'undergrad'
              ) != -1
            "
          >
            <v-label>What was your undergraduate degree major?</v-label>
            <v-text-field
              readonly
              class="mx-5"
              label="Undergrad degree*"
              type="text"
              outlined
              dense
              :rules="[rules.required]"
              v-model="studentApplication.returnMissionaryScholarship.undergrad"
            />
          </div>
          <div
            v-if="
              studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
                'graduate'
              ) != -1
            "
          >
            <v-label>What was your graduate degree major?</v-label>
            <v-text-field
              readonly
              class="mx-5"
              label="Graduate degree*"
              type="text"
              outlined
              dense
              :rules="[rules.required]"
              v-model="studentApplication.returnMissionaryScholarship.graduate"
            />
          </div>
          <v-label
            >If you have a recent TOEFL score, please enter the combined score
            below.</v-label
          >
          <v-text-field
            readonly
            class="mx-5"
            label="TOEFL score"
            type="number"
            outlined
            dense
            v-model="studentApplication.returnMissionaryScholarship.toeflScore"
          />
          <v-label>Name of Mission</v-label>
          <v-text-field
            readonly
            class="mx-5"
            label="Mission"
            type="text"
            outlined
            dense
            v-model="
              studentApplication.returnMissionaryScholarship.nameOfMission
            "
          />
          <v-label>Missionary Service Start Date</v-label>
          <v-text-field
            readonly
            class="mx-5"
            label="TOEFL score"
            type="date"
            outlined
            dense
            v-model="
              studentApplication.returnMissionaryScholarship.startDateMission
            "
          />
          <v-label>Missionary Service Completion Date</v-label>
          <v-text-field
            readonly
            class="mx-5"
            label="TOEFL score"
            type="date"
            outlined
            dense
            v-model="
              studentApplication.returnMissionaryScholarship.endDateMission
            "
          />
          <v-label class="b-2"
            >Please write a short essay (no more than 500 words) explaining how
            studying English will benefit you in your future goals.</v-label
          >
          <v-textarea
            readonly
            outlined
            label="Essay"
            class="mx-5"
            value=""
            auto-grow
            v-model="
              studentApplication.returnMissionaryScholarship
                .returnMissionaryEssay
            "
          ></v-textarea>
          <v-label
            >NOTE: Your Returned Missionary Scholarship application will not be
            complete until you meet with your bishop. After you submit this
            application, you will receive an email with a link to a document
            that contains instructions for your bishop. Print those instructions
            and take them with you to give to your bishop when you meet with
            him. If you have further questions regarding this application, talk
            to Sandy Hatchett in the front office.</v-label
          >
          <v-radio-group v-model="studentApplicationDynamicChanges.rmAgreement">
            <v-radio
              label="I understand the my Returned Missionary Scholarship is not complete until I meet with my bishop."
              readonly
              :value="true"
            ></v-radio>
            <v-radio readonly label="Never mind" :value="false"></v-radio>
          </v-radio-group>
        </v-card-text>
      </div>
      <StudentNavigation :firstOrLast="'final'" />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState, mapGetters } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "StudentReview",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
    },
    emailMessage: "Email Address*",
    languageLevels: [
      "Foundations Prep",
      "Foundations A",
      "Foundations B",
      "Foundations C",
      "Academic A",
      "Academic B",
      "University Prep",
    ],
  }),
  computed: {
    ...mapState("applicationStore", [
      "studentApplication",
      "studentApplicationDynamicChanges",
    ]),
    ...mapGetters("applicationStore", [
      "itoScholarship",
      "tbsScholarship",
      "rmScholarship",
    ]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
