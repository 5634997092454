import ApplicationService from "../services/ApplicationService";
import { BISHOP_STEPS, STUDENT_STEPS } from "@/constants";

export const namespaced = true;

export const state = {
  isLoading: false,
  currentBishopStep: 1,
  currentStudentStep: 1,
  studentStepsCompleted: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  },
  bishopStepsCompleted: {
    1: false,
    2: false,
    3: false,
  },
  currentSemester: {},
  bishopApplication: {
    id: "",
    bishopName: "",
    phoneNumber: "",
    email: "",
    unitName: "",
    unitID: "",
    studentName: "",
    studentNetID: "",
    bishop_recommendation: [],
    additionalDetail: "",
  },
  studentApplication: {
    id: "",
    studentFullName: "",
    studentNetID: "",
    firstSemesterAtELC: "",
    email: "",
    currentLevel: "",
    scholarshipChoice: "",
    tomBeckySmithEssay: "",
    itoEssay: "",
    studyingGoals: "",
    returnMissionaryScholarship: {
      marriageStatus: "",
      schoolingBackground: [],
      undergrad: "",
      graduate: "",
      toeflScore: "",
      nameOfMission: "",
      startDateMission: "",
      endDateMission: "",
      returnMissionaryEssay: "",
    },
  },
  studentApplicationDynamicChanges: {
    elcStudentStatus: "",
    elcStudyAbroadStatus: "",
    elcExcellenceAgreement: [],
    itoAgreement: "2",
    tbsScholarship: "",
    itoScholarship: "",
    rmScholarship: "",
    rmAgreement: "",
  },
};

export const actions = {
  async postCreateStudentApplication() {
    if (
      !state.studentApplicationDynamicChanges.tbsScholarship ||
      state.studentApplication.currentLevel == "Academic A" ||
      state.studentApplication.currentLevel == "Academic B" ||
      state.studentApplication.currentLevel == "University Prep"
    ) {
      delete state.studentApplication.tomBeckySmithEssay;
    }
    if (
      !state.studentApplicationDynamicChanges.rmAgreement ||
      !state.studentApplicationDynamicChanges.rmScholarship ||
      state.studentApplication.currentLevel == "Foundations Prep" ||
      state.studentApplication.currentLevel == "Foundations A" ||
      state.studentApplication.currentLevel == "Foundations B" ||
      state.studentApplication.currentLevel == "Foundations C"
    ) {
      delete state.studentApplication.returnMissionaryScholarship;
    }
    if (
      !state.studentApplicationDynamicChanges.itoAgreement ||
      !state.studentApplicationDynamicChanges.itoScholarship
    ) {
      delete state.studentApplication.itoEssay;
    }
    await ApplicationService.postCreateStudentApplication(
      state.studentApplication
    )
      .then((res) => {
        if (res.status == 200) {
          state.studentApplication = {
            id: "",
            studentFullName: "",
            studentNetID: "",
            firstSemesterAtELC: "",
            email: "",
            currentLevel: "",
            scholarshipChoice: "",
            tomBeckySmithEssay: "",
            itoEssay: "",
            studyingGoals: "",
            returnMissionaryScholarship: {
              marriageStatus: "",
              schoolingBackground: [],
              undergrad: "",
              graduate: "",
              toeflScore: "",
              nameOfMission: "",
              startDateMission: "",
              endDateMission: "",
              returnMissionaryEssay: "",
            },
          };
          state.studentApplicationDynamicChanges = {
            elcStudentStatus: "",
            elcStudyAbroadStatus: "",
            elcExcellenceAgreement: [],
            itoAgreement: "2",
            tbsScholarship: "",
            itoScholarship: "",
            rmScholarship: "",
            rmAgreement: "",
          };
          state.currentStudentStep = 1;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  async postCreateBishopApplication() {
    await ApplicationService.postCreateBishopApplication(
      state.bishopApplication
    )
      .then((res) => {
        if (res.status == 200) {
          state.bishopApplication = {
            id: "",
            bishopName: "",
            phoneNumber: "",
            email: "",
            unitName: "",
            unitID: "",
            studentName: "",
            studentNetID: "",
            bishop_recommendation: [],
            additionalDetail: "",
          };
          state.currentBishopStep = 1;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  },
  async getCurrentSemester({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await ApplicationService.getCurrentSemester()
      .then((result) => {
        commit("SET_CURRENT_SEMESTER", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },

  setBishopCurrentStepDirectly({ commit }, step) {
    commit("SET_BISHOP_CURRENT_STEP_DIRECTLY", step);
  },
  setStudentCurrentStepDirectly({ commit }, step) {
    commit("SET_STUDENT_CURRENT_STEP_DIRECTLY", step);
  },
  setBishopCurrentStep({ commit }, step) {
    commit("SET_BISHOP_CURRENT_STEP", step);
  },
  setStudentCurrentStep({ commit }, step) {
    commit("SET_STUDENT_CURRENT_STEP", step);
  },
};

export const mutations = {
  SET_CURRENT_SEMESTER(state, payload) {
    state.currentSemester = payload.data;
  },
  SET_IS_LOADING(state, value) {
    if (value === undefined) {
      state.isLoading = true;
    } else {
      state.isLoading = value;
    }
  },
  SET_BISHOP_CURRENT_STEP_DIRECTLY(state, step) {
    if (
      step <= BISHOP_STEPS.REVIEW ||
      step >= BISHOP_STEPS.BISHOP_INFORMATION
    ) {
      state.currentBishopStep = step;
    }
  },
  SET_STUDENT_CURRENT_STEP_DIRECTLY(state, step) {
    if (
      step <= STUDENT_STEPS.REVIEW ||
      step >= STUDENT_STEPS.STUDENT_QUALIFICATIONS
    ) {
      state.currentStudentStep = step;
    }
  },
  SET_BISHOP_CURRENT_STEP(state, step) {
    if (step === "next") {
      if (state.currentBishopStep < BISHOP_STEPS.REVIEW) {
        state.currentBishopStep++;
      } else {
        state.currentBishopStep = BISHOP_STEPS.RECOMMENDATION;
      }
    } else {
      if (state.currentBishopStep > BISHOP_STEPS.BISHOP_INFORMATION) {
        state.currentBishopStep--;
      } else {
        state.currentBishopStep = BISHOP_STEPS.BISHOP_INFORMATION;
      }
    }
  },
  SET_STUDENT_CURRENT_STEP(state, step) {
    if (step === "next") {
      if (state.currentStudentStep < STUDENT_STEPS.REVIEW) {
        state.currentStudentStep++;
        if (!getters.tbsScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.TBS_SCHOLARSHIP) {
            state.currentStudentStep++;
          }
        }
        if (!getters.itoScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.ITO_SCHOLARSHIP) {
            state.currentStudentStep++;
          }
        }
        if (!getters.rmScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.RM_SCHOLARSHIP) {
            state.currentStudentStep++;
          }
        }
      } else {
        state.currentStudentStep = STUDENT_STEPS.RM_SCHOLARSHIP;
      }
    } else {
      if (state.currentStudentStep > STUDENT_STEPS.STUDENT_QUALIFICATIONS) {
        state.currentStudentStep--;
        if (!getters.rmScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.RM_SCHOLARSHIP) {
            state.currentStudentStep--;
          }
        }
        if (!getters.itoScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.ITO_SCHOLARSHIP) {
            state.currentStudentStep--;
          }
        }
        if (!getters.tbsScholarship()) {
          if (state.currentStudentStep == STUDENT_STEPS.TBS_SCHOLARSHIP) {
            state.currentStudentStep--;
          }
        }
      } else {
        state.currentStudentStep = STUDENT_STEPS.STUDENT_QUALIFICATIONS;
      }
    }
  },
};

export const getters = {
  allBishopStepsCompleted() {
    return (
      state.bishopStepsCompleted[BISHOP_STEPS.BISHOP_INFORMATION] &&
      state.bishopStepsCompleted[BISHOP_STEPS.STUDENT_INFORMATION] &&
      state.bishopStepsCompleted[BISHOP_STEPS.RECOMMENDATION]
    );
  },
  allStudentStepsCompleted() {
    return (
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_QUALIFICATIONS] &&
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_INFORMATION] &&
      state.studentStepsCompleted[STUDENT_STEPS.EXCELLENCE_AGREEMENT] &&
      state.studentStepsCompleted[STUDENT_STEPS.EXCELLENCE_AGREEMENT] &&
      state.studentStepsCompleted[STUDENT_STEPS.SCHOLARSHIP_SELECTION] &&
      state.studentStepsCompleted[STUDENT_STEPS.TBS_SCHOLARSHIP] &&
      state.studentStepsCompleted[STUDENT_STEPS.ITO_SCHOLARSHIP] &&
      state.studentStepsCompleted[STUDENT_STEPS.RM_SCHOLARSHIP]
    );
  },
  bishopInfoComplete() {
    if (
      !!state.bishopApplication.bishopName &&
      !!state.bishopApplication.phoneNumber &&
      !!state.bishopApplication.email &&
      !!state.bishopApplication.unitName &&
      !!state.bishopApplication.unitID
    ) {
      state.bishopStepsCompleted[BISHOP_STEPS.BISHOP_INFORMATION] = true;
      return true;
    } else {
      state.bishopStepsCompleted[BISHOP_STEPS.bishopInfoComplete] = false;
      return false;
    }
  },
  bishopStudentComplete() {
    if (
      !!state.bishopApplication.studentName &&
      !!state.bishopApplication.studentNetID &&
      state.bishopApplication.studentNetID.length == 9
    ) {
      state.bishopStepsCompleted[BISHOP_STEPS.STUDENT_INFORMATION] = true;
      return true;
    } else {
      state.bishopStepsCompleted[BISHOP_STEPS.STUDENT_INFORMATION] = false;
      return false;
    }
  },
  bishopRecommendationComplete() {
    if (state.bishopApplication.bishop_recommendation.length >= 1) {
      state.bishopStepsCompleted[BISHOP_STEPS.RECOMMENDATION] = true;
      return true;
    } else {
      state.bishopStepsCompleted[BISHOP_STEPS.RECOMMENDATION] = false;
      return false;
    }
  },
  studentQualified() {
    if (
      state.studentApplicationDynamicChanges.elcStudentStatus &&
      state.studentApplicationDynamicChanges.elcStudyAbroadStatus
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_QUALIFICATIONS] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_QUALIFICATIONS] = false;
      return false;
    }
  },
  studentInformationComplete() {
    if (
      state.studentApplication.studentFullName &&
      state.studentApplication.studentNetID &&
      state.studentApplication.firstSemesterAtELC &&
      state.studentApplication.email &&
      state.studentApplication.currentLevel &&
      state.studentApplication.studyingGoals.length >= 1 &&
      state.studentApplication.studyingGoals.length <= 3000
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_INFORMATION] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.STUDENT_INFORMATION] = false;
      return false;
    }
  },
  studentExcellenceAgreementComplete() {
    if (
      state.studentApplicationDynamicChanges.elcExcellenceAgreement.length == 3
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.EXCELLENCE_AGREEMENT] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.EXCELLENCE_AGREEMENT] = false;
      return false;
    }
  },
  studentScholarshipSelectionComplete() {
    if (
      state.studentApplicationDynamicChanges.tbsScholarship |
      state.studentApplicationDynamicChanges.itoScholarship |
      state.studentApplicationDynamicChanges.rmScholarship
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.SCHOLARSHIP_SELECTION] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.SCHOLARSHIP_SELECTION] = false;
      return false;
    }
  },
  tbsScholarshipComplete() {
    if (
      state.studentApplication.tomBeckySmithEssay &&
      state.studentApplicationDynamicChanges.tbsScholarship &&
      state.studentApplication.tomBeckySmithEssay.length <= 2500
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.TBS_SCHOLARSHIP] = true;
      return true;
    } else if (!state.studentApplicationDynamicChanges.tbsScholarship) {
      state.studentStepsCompleted[STUDENT_STEPS.TBS_SCHOLARSHIP] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.TBS_SCHOLARSHIP] = false;
      return false;
    }
  },
  itoScholarshipComplete() {
    if (
      state.studentApplication.itoEssay &&
      state.studentApplicationDynamicChanges.itoScholarship &&
      state.studentApplicationDynamicChanges.itoAgreement != 2 &&
      state.studentApplication.itoEssay.length <= 2500
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.ITO_SCHOLARSHIP] = true;
      return true;
    } else if (!state.studentApplicationDynamicChanges.itoScholarship) {
      state.studentStepsCompleted[STUDENT_STEPS.ITO_SCHOLARSHIP] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.ITO_SCHOLARSHIP] = false;
      return false;
    }
  },
  studentRMScholarshipComplete() {
    if (
      state.studentApplicationDynamicChanges.rmScholarship.length == 0 ||
      state.studentApplicationDynamicChanges.rmAgreement.length == 0
        ? false
        : !state.studentApplicationDynamicChanges.rmAgreement ||
          (state.studentApplication.returnMissionaryScholarship.marriageStatus
            .length > 0 &&
            state.studentApplication.returnMissionaryScholarship
              .schoolingBackground.length >= 1 &&
            state.studentApplication.returnMissionaryScholarship.nameOfMission
              .length >= 1 &&
            state.studentApplication.returnMissionaryScholarship
              .startDateMission.length >= 1 &&
            state.studentApplication.returnMissionaryScholarship.endDateMission
              .length >= 1 &&
            state.studentApplication.returnMissionaryScholarship
              .returnMissionaryEssay.length >= 1 &&
            state.studentApplication.returnMissionaryScholarship
              .returnMissionaryEssay.length <= 3000 &&
            (state.studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
              "undergrad"
            ) != -1
              ? state.studentApplication.returnMissionaryScholarship.undergrad
                  .length >= 1
              : true) &&
            (state.studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
              "graduate"
            ) != -1
              ? state.studentApplication.returnMissionaryScholarship.graduate
                  .length >= 1
              : true))
    ) {
      state.studentStepsCompleted[STUDENT_STEPS.RM_SCHOLARSHIP] = true;
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.RM_SCHOLARSHIP] = false;
      return false;
    }
  },
  // Continue Making Verification on student Form
  itoScholarship() {
    if (state.studentApplicationDynamicChanges.itoScholarship) {
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.ITO_SCHOLARSHIP] = true;
      return false;
    }
  },
  tbsScholarship() {
    if (state.studentApplicationDynamicChanges.tbsScholarship) {
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.TBS_SCHOLARSHIP] = true;
      return false;
    }
  },
  rmScholarship() {
    if (state.studentApplicationDynamicChanges.rmScholarship) {
      return true;
    } else {
      state.studentStepsCompleted[STUDENT_STEPS.RM_SCHOLARSHIP] = true;
      return false;
    }
  },
};
