var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"elevation":"25","outlined":""}},[_c('v-card-title',[_vm._v(" Scholarship Selection ")]),_c('v-card-subtitle',[_vm._v(" Please select which scholarships you would like to apply for (check all that apply): ")]),_c('v-card-text',[(
					_vm.studentApplication.currentLevel == 'Foundations Prep' ||
					_vm.studentApplication.currentLevel == 'Foundations A' ||
					_vm.studentApplication.currentLevel == 'Foundations B' ||
					_vm.studentApplication.currentLevel == 'Foundations C'
				)?_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"Tom and Becky Smith Scholarship (must be currently studying in Foundations Prep, Foundations A, Foundations B, or Foundations C)","value":"1","required":""},model:{value:(_vm.studentApplicationDynamicChanges.tbsScholarship),callback:function ($$v) {_vm.$set(_vm.studentApplicationDynamicChanges, "tbsScholarship", $$v)},expression:"studentApplicationDynamicChanges.tbsScholarship"}}):_vm._e(),_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"Ito Scholarship","value":"1","required":""},model:{value:(_vm.studentApplicationDynamicChanges.itoScholarship),callback:function ($$v) {_vm.$set(_vm.studentApplicationDynamicChanges, "itoScholarship", $$v)},expression:"studentApplicationDynamicChanges.itoScholarship"}}),(
					_vm.studentApplication.currentLevel == 'Foundations C' ||
					_vm.studentApplication.currentLevel == 'Academic A' ||
					_vm.studentApplication.currentLevel == 'Academic B' ||
					_vm.studentApplication.currentLevel == 'University Prep'
				)?_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"Returned Missionary Scholarship","value":"1","required":""},model:{value:(_vm.studentApplicationDynamicChanges.rmScholarship),callback:function ($$v) {_vm.$set(_vm.studentApplicationDynamicChanges, "rmScholarship", $$v)},expression:"studentApplicationDynamicChanges.rmScholarship"}}):_vm._e()],1),_c('br'),_c('StudentNavigation')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }