exports.STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  GONE: 410,
};

exports.BISHOP_STEPS = {
  BISHOP_INFORMATION: 1,
  STUDENT_INFORMATION: 2,
  RECOMMENDATION: 3,
  REVIEW: 4,
};

exports.STUDENT_STEPS = {
  STUDENT_QUALIFICATIONS: 1,
  STUDENT_INFORMATION: 2,
  EXCELLENCE_AGREEMENT: 3,
  SCHOLARSHIP_SELECTION: 4,
  TBS_SCHOLARSHIP: 5,
  ITO_SCHOLARSHIP: 6,
  RM_SCHOLARSHIP: 7,
  REVIEW: 8,
};

exports.ADMIN_OPTIONS = {
  MANAGE_SEMESTERS: 1,
  MANAGE_ADMINS: 2,
  MANAGE_APPLICATIONS: 3,
  MANAGE_EMAIL_STUDENT: 4,
  MANAGE_EMAIL_BISHOP: 5,
  MANAGE_EMAIL_BISHOP_INSTRUCTIONS: 6,
};
