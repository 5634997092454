<template>
	<div>
		<v-card class="mb-5" elevation="25" outlined>
			<v-card-title> Scholarship Selection </v-card-title>
			<v-card-subtitle>
				Please select which scholarships you would like to apply for
				(check all that apply):
			</v-card-subtitle>
			<v-card-text>
				<v-checkbox
					v-if="
						studentApplication.currentLevel == 'Foundations Prep' ||
						studentApplication.currentLevel == 'Foundations A' ||
						studentApplication.currentLevel == 'Foundations B' ||
						studentApplication.currentLevel == 'Foundations C'
					"
					class="m-0 p-0"
					v-model="studentApplicationDynamicChanges.tbsScholarship"
					label="Tom and Becky Smith Scholarship (must be currently studying in Foundations Prep, Foundations A, Foundations B, or Foundations C)"
					value="1"
					required
				></v-checkbox>
				<v-checkbox
					class="m-0 p-0"
					v-model="studentApplicationDynamicChanges.itoScholarship"
					label="Ito Scholarship"
					value="1"
					required
				></v-checkbox>
				<v-checkbox
					v-if="
						studentApplication.currentLevel == 'Foundations C' ||
						studentApplication.currentLevel == 'Academic A' ||
						studentApplication.currentLevel == 'Academic B' ||
						studentApplication.currentLevel == 'University Prep'
					"
					class="m-0 p-0"
					v-model="studentApplicationDynamicChanges.rmScholarship"
					label="Returned Missionary Scholarship"
					value="1"
					required
				></v-checkbox>
			</v-card-text>
			<br />
			<StudentNavigation />
		</v-card>
	</div>
</template>

<script>
	// import { STEPS, COUNTRIES } from "@/constants.js";
	import { mapState } from "vuex";
	import StudentNavigation from "@/components/student/StudentNavigation.vue";
	export default {
		name: "StudentScholarshipSelection",
		components: {
			StudentNavigation,
		},
		data: () => ({
			rules: {
				required: (value) => !!value || "Required",
				range: (value) =>
					value >= 0 || "This value cannot be negative or empty",
			},
		}),
		computed: {
			...mapState("applicationStore", [
				"studentApplication",
				"studentApplicationDynamicChanges",
			]),
		},
		methods: {},
		async created() {},
	};
</script>

<style scoped>
	.sizeRemove {
		max-width: 30px;
		max-height: 30px;
	}
	::v-deep .v-input--selection-controls {
		margin-top: 0px;
		padding-top: 0px;
	}
</style>
