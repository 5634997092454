<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Returned Missionary Scholarship </v-card-title>
      <v-card-subtitle>
        Please provide us with the following information.
      </v-card-subtitle>
      <v-card-text>
        <v-label
          >NOTE: Your Returned Missionary Scholarship application will not be
          complete until you meet with your bishop. After you submit this
          application, you will receive an email with a link to a document that
          contains instructions for your bishop. Print those instructions and
          take them with you to give to your bishop when you meet with him. If
          you have further questions regarding this application, talk to Sandy
          Hatchett in the front office.</v-label
        >
        <v-radio-group v-model="studentApplicationDynamicChanges.rmAgreement">
          <v-radio
            label="I understand the my Returned Missionary Scholarship is not complete until I meet with my bishop."
            :value="true"
          ></v-radio>
          <v-radio label="Never mind" :value="false"></v-radio>
        </v-radio-group>
        <v-label>Please select your marital status below.</v-label>
        <v-radio-group
          v-model="
            studentApplication.returnMissionaryScholarship.marriageStatus
          "
        >
          <v-radio label="Married" value="married"></v-radio>
          <v-radio label="Single" value="single"></v-radio>
        </v-radio-group>
        <v-label>Please check all that apply to you</v-label>
        <v-checkbox
          class="m-0 p-0"
          v-model="
            studentApplication.returnMissionaryScholarship.schoolingBackground
          "
          label="I have graduated from highschool. (required)"
          value="highschool"
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="
            studentApplication.returnMissionaryScholarship.schoolingBackground
          "
          label="I have an undergraduate degree."
          value="undergrad"
        ></v-checkbox>
        <v-checkbox
          class="m-0 p-0"
          v-model="
            studentApplication.returnMissionaryScholarship.schoolingBackground
          "
          label="I have a graduate degree."
          value="graduate"
        ></v-checkbox>
        <div
          v-if="
            studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
              'undergrad'
            ) != -1
          "
        >
          <v-label>What was your undergraduate degree major?</v-label>
          <v-text-field
            class="mx-5"
            label="Undergrad degree*"
            type="text"
            outlined
            dense
            :rules="[rules.required]"
            v-model="studentApplication.returnMissionaryScholarship.undergrad"
          />
        </div>
        <div
          v-if="
            studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
              'graduate'
            ) != -1
          "
        >
          <v-label>What was your graduate degree major?</v-label>
          <v-text-field
            class="mx-5"
            label="Graduate degree*"
            type="text"
            outlined
            dense
            :rules="[rules.required]"
            v-model="studentApplication.returnMissionaryScholarship.graduate"
          />
        </div>
        <v-label
          >If you have a recent TOEFL score, please enter the combined score
          below.</v-label
        >
        <v-text-field
          class="mx-5"
          label="TOEFL score"
          type="number"
          outlined
          dense
          v-model="studentApplication.returnMissionaryScholarship.toeflScore"
        />
        <v-label>Name of Mission</v-label>
        <v-text-field
          class="mx-5"
          label="Mission"
          type="text"
          outlined
          dense
          v-model="studentApplication.returnMissionaryScholarship.nameOfMission"
        />
        <v-label>Missionary Service Start Date</v-label>
        <v-text-field
          class="mx-5"
          label="TOEFL score"
          type="date"
          outlined
          dense
          v-model="
            studentApplication.returnMissionaryScholarship.startDateMission
          "
        />
        <v-label>Missionary Service Completion Date</v-label>
        <v-text-field
          class="mx-5"
          label="TOEFL score"
          type="date"
          outlined
          dense
          v-model="
            studentApplication.returnMissionaryScholarship.endDateMission
          "
        />
        <v-label class="b-2"
          >Please write a short essay (no more than 500 words) explaining how
          studying English will benefit you in your future goals.</v-label
        >
        <v-textarea
          outlined
          class="mx-5"
          label="Essay"
          value=""
          auto-grow
          :rules="[rules.maxLength]"
          v-model="
            studentApplication.returnMissionaryScholarship.returnMissionaryEssay
          "
        ></v-textarea>
      </v-card-text>

      <StudentNavigation />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "RMScholarship",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      maxLength: (value) =>
        value.length <= 3000 ||
        "Length of essay may not exceed 3000 characters",
    },
  }),
  computed: {
    ...mapState("applicationStore", [
      "studentApplication",
      "studentApplicationDynamicChanges",
    ]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
::v-deep .v-input__slot {
  margin-top: 0.5rem;
}
</style>
