<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Student Information </v-card-title>
      <v-card-subtitle>
        Please provide us with the following information.
      </v-card-subtitle>
      <v-card-text>
        <v-text-field
          class="mx-5"
          label="Student Full Name*"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="studentApplication.studentFullName"
        />
        <v-text-field
          class="mx-5"
          label="BYU ID Number(9 Digits)*"
          type="number"
          outlined
          dense
          :rules="[rules.required, rules.length]"
          v-model="studentApplication.studentNetID"
        />
        <v-text-field
          class="mx-5"
          label="First Semester at the ELC*"
          type="text"
          outlined
          dense
          :rules="[rules.required]"
          v-model="studentApplication.firstSemesterAtELC"
        />
        <v-text-field
          class="mx-5"
          :label="emailMessage"
          type="email"
          outlined
          dense
          :rules="[rules.required]"
          v-model="studentApplication.email"
        />
        <v-label>What level are you currently enrolled in at the ELC.</v-label>
        <v-select
          class="mx-5"
          :items="languageLevels"
          dense
          outlined
          v-model="studentApplication.currentLevel"
          required
        ></v-select>
        <v-label
          >Briefly but with specific details describe your goals for studying
          English.</v-label
        >
        <v-textarea
          class="mx-5"
          outlined
          label="Essay"
          value=""
          auto-grow
          :rules="[rules.maxLength]"
          v-model="studentApplication.studyingGoals"
        ></v-textarea>
        <br />
      </v-card-text>

      <StudentNavigation />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "StudentInfo",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      length: (value) => value.length == 9 || "The length must be 9 digits",
      maxLength: (value) =>
        value.length <= 3000 ||
        "Length of essay may not exceed 3000 characters",
    },
    emailMessage: "Email Address*",
    languageLevels: [
      "Foundations Prep",
      "Foundations A",
      "Foundations B",
      "Foundations C",
      "Academic A",
      "Academic B",
      "University Prep",
    ],
  }),
  computed: {
    ...mapState("applicationStore", ["studentApplication"]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
