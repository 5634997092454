var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"elevation":"25","outlined":""}},[_c('v-card-title',[_vm._v(" Returned Missionary Scholarship ")]),_c('v-card-subtitle',[_vm._v(" Please provide us with the following information. ")]),_c('v-card-text',[_c('v-label',[_vm._v("NOTE: Your Returned Missionary Scholarship application will not be complete until you meet with your bishop. After you submit this application, you will receive an email with a link to a document that contains instructions for your bishop. Print those instructions and take them with you to give to your bishop when you meet with him. If you have further questions regarding this application, talk to Sandy Hatchett in the front office.")]),_c('v-radio-group',{model:{value:(_vm.studentApplicationDynamicChanges.rmAgreement),callback:function ($$v) {_vm.$set(_vm.studentApplicationDynamicChanges, "rmAgreement", $$v)},expression:"studentApplicationDynamicChanges.rmAgreement"}},[_c('v-radio',{attrs:{"label":"I understand the my Returned Missionary Scholarship is not complete until I meet with my bishop.","value":true}}),_c('v-radio',{attrs:{"label":"Never mind","value":false}})],1),_c('v-label',[_vm._v("Please select your marital status below.")]),_c('v-radio-group',{model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.marriageStatus
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "marriageStatus", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.marriageStatus\n        "}},[_c('v-radio',{attrs:{"label":"Married","value":"married"}}),_c('v-radio',{attrs:{"label":"Single","value":"single"}})],1),_c('v-label',[_vm._v("Please check all that apply to you")]),_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"I have graduated from highschool. (required)","value":"highschool"},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.schoolingBackground
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "schoolingBackground", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.schoolingBackground\n        "}}),_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"I have an undergraduate degree.","value":"undergrad"},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.schoolingBackground
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "schoolingBackground", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.schoolingBackground\n        "}}),_c('v-checkbox',{staticClass:"m-0 p-0",attrs:{"label":"I have a graduate degree.","value":"graduate"},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.schoolingBackground
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "schoolingBackground", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.schoolingBackground\n        "}}),(
          _vm.studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
            'undergrad'
          ) != -1
        )?_c('div',[_c('v-label',[_vm._v("What was your undergraduate degree major?")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Undergrad degree*","type":"text","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.studentApplication.returnMissionaryScholarship.undergrad),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "undergrad", $$v)},expression:"studentApplication.returnMissionaryScholarship.undergrad"}})],1):_vm._e(),(
          _vm.studentApplication.returnMissionaryScholarship.schoolingBackground.indexOf(
            'graduate'
          ) != -1
        )?_c('div',[_c('v-label',[_vm._v("What was your graduate degree major?")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Graduate degree*","type":"text","outlined":"","dense":"","rules":[_vm.rules.required]},model:{value:(_vm.studentApplication.returnMissionaryScholarship.graduate),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "graduate", $$v)},expression:"studentApplication.returnMissionaryScholarship.graduate"}})],1):_vm._e(),_c('v-label',[_vm._v("If you have a recent TOEFL score, please enter the combined score below.")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"TOEFL score","type":"number","outlined":"","dense":""},model:{value:(_vm.studentApplication.returnMissionaryScholarship.toeflScore),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "toeflScore", $$v)},expression:"studentApplication.returnMissionaryScholarship.toeflScore"}}),_c('v-label',[_vm._v("Name of Mission")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Mission","type":"text","outlined":"","dense":""},model:{value:(_vm.studentApplication.returnMissionaryScholarship.nameOfMission),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "nameOfMission", $$v)},expression:"studentApplication.returnMissionaryScholarship.nameOfMission"}}),_c('v-label',[_vm._v("Missionary Service Start Date")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"TOEFL score","type":"date","outlined":"","dense":""},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.startDateMission
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "startDateMission", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.startDateMission\n        "}}),_c('v-label',[_vm._v("Missionary Service Completion Date")]),_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"TOEFL score","type":"date","outlined":"","dense":""},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.endDateMission
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "endDateMission", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.endDateMission\n        "}}),_c('v-label',{staticClass:"b-2"},[_vm._v("Please write a short essay (no more than 500 words) explaining how studying English will benefit you in your future goals.")]),_c('v-textarea',{staticClass:"mx-5",attrs:{"outlined":"","label":"Essay","value":"","auto-grow":"","rules":[_vm.rules.maxLength]},model:{value:(
          _vm.studentApplication.returnMissionaryScholarship.returnMissionaryEssay
        ),callback:function ($$v) {_vm.$set(_vm.studentApplication.returnMissionaryScholarship, "returnMissionaryEssay", $$v)},expression:"\n          studentApplication.returnMissionaryScholarship.returnMissionaryEssay\n        "}})],1),_c('StudentNavigation')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }