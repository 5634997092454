const baseUrl = "https://elcscholarship.byu.edu/api/";
// const baseUrl = "http://localhost:3001/";

import axios from "axios";

import { mapState } from "vuex";

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem("token");
	const user = localStorage.getItem("user");
	config.headers.authorization = token;
	config.headers.current_user = user;
	return config;
});

export default {
	data() {
		return {};
	},
	methods: {
		...mapState({
			user: (state) => state.user,
			jwtToken: (state) => state.jwtToken,
		}),
	},
	async getCurrentSemester() {
		return await axios.get(baseUrl + `semester/active`);
	},
	async postCreateStudentApplication(application) {
		return await axios.post(baseUrl + "student", {
			application: application,
		});
	},
	async postCreateBishopApplication(application) {
		return await axios.post(baseUrl + "student/bishop", {
			application: application,
		});
	},
};
