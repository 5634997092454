<template>
  <v-container>
    <v-row class="mt-5">
      <v-col xs="12" sm="6" md="4" class="mr-3">
        <div class="sidebar">
          <StudentSteps />
        </div>
      </v-col>
      <v-col xs="12" sm="6" md="6" elevation="25">
        <StudentQualifications
          v-if="currentStudentStep == steps.STUDENT_QUALIFICATIONS"
        />
        <StudentInformation
          v-if="currentStudentStep == steps.STUDENT_INFORMATION"
        />
        <ExcellenceAgreement
          v-if="currentStudentStep == steps.EXCELLENCE_AGREEMENT"
        />
        <StudentScholarshipSelection
          v-if="currentStudentStep == steps.SCHOLARSHIP_SELECTION"
        />
        <TBSScholarship v-if="currentStudentStep == steps.TBS_SCHOLARSHIP" />
        <ITOScholarship v-if="currentStudentStep == steps.ITO_SCHOLARSHIP" />
        <RMScholarship v-if="currentStudentStep == steps.RM_SCHOLARSHIP" />
        <StudentReview v-if="currentStudentStep == steps.REVIEW" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { STUDENT_STEPS } from "@/constants.js";
import StudentSteps from "@/components/student/StudentSteps.vue";
import { mapState } from "vuex";
import StudentQualifications from "@/components/student/steps/StudentQualifications.vue";
import StudentInformation from "@/components/student/steps/StudentInformation.vue";
import ExcellenceAgreement from "@/components/student/steps/ExcellenceAgreement.vue";
import StudentScholarshipSelection from "@/components/student/steps/StudentScholarshipSelection.vue";
import TBSScholarship from "@/components/student/steps/TBSScholarship.vue";
import ITOScholarship from "@/components/student/steps/ITOScholarship.vue";
import RMScholarship from "@/components/student/steps/RMScholarship.vue";
import StudentReview from "@/components/student/steps/StudentReview.vue";
export default {
  name: "StudentPage",
  components: {
    StudentSteps,
    StudentQualifications,
    StudentInformation,
    ExcellenceAgreement,
    StudentScholarshipSelection,
    TBSScholarship,
    ITOScholarship,
    RMScholarship,
    StudentReview,
  },
  data() {
    return {
      steps: STUDENT_STEPS,
      dialog: false,
    };
  },
  computed: {
    ...mapState("applicationStore", ["currentStudentStep"]),
  },
  methods: {},
  async created() {},
  watch: {
    currentStudentStep(value) {
      localStorage.studentStep = value;
    },
  },
};
</script>

<style scoped>
.docsDialog {
  height: 600px;
}
</style>
