<template>
  <div>
    <v-app-bar color="secondary">
      <!-- <v-img src="@/assets/logo.png" max-height="50" max-width="300" class="mr-5"></v-img> -->
      <router-link to="/" class="homeLink">
        <v-toolbar-title class="title">
          ELC | Scholarship Application
        </v-toolbar-title>
      </router-link>
      <v-spacer />
      <v-icon id="menu-activator" color="white" class="mr-3" large>
        mdi-account-circle-outline
      </v-icon>
      <v-menu activator="#menu-activator">
        <v-list>
          <v-list-item v-if="!user">
            <v-list-item-title>
              <span
                @click="
                  login().then(({ state, token, user, error }) => {
                    localStorage.setItem('token', token['authorizationHeader']);
                    localStorage.setItem('user', user.netId);
                    this.$store.commit('SET_USER', user.netId);
                    this.$store.commit('SET_JWT', token['authorizationHeader']);
                  })
                "
                >Login</span
              ></v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="user">
            <v-list-item-title>
              <span
                @click="
                  logout().then(({ state, token, user, error }) => {
                    localStorage.removeItem(
                      'token',
                      token['authorizationHeader']
                    );
                    localStorage.removeItem('user', user.netId);
                    this.$store.commit('SET_USER', '');
                    this.$store.commit('SET_JWT', '');
                  })
                "
                >Logout</span
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-progress-linear
      v-if="isLoading"
      color="success"
      indeterminate
      absolute
      stream
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { login, logout } from "@byuweb/browser-oauth";
export default {
  name: "TopBar",
  data() {
    return {
      drawer: false,
      login: login,
      logout: logout,
    };
  },
  methods: {},
  components: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      jwtToken: (state) => state.jwtToken,
      user: (state) => state.user,
    }),
  },
  // Make a watcher to watch login and logout
};
</script>

<style>
.title {
  color: white;
}
.homeLink {
  text-decoration: none;
}
</style>
