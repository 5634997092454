<template>
  <div>
    <v-card class="mb-5" elevation="25" outlined>
      <v-card-title> Ito Scholarship </v-card-title>
      <v-card-subtitle>
        Describe a set of challenges or difficult circumstances that you have
        overcome while learning English. These can be difficulties you faced
        before coming to the ELC or while at the ELC. In addition to describing
        these difficulties, describe your efforts to contribute positively to
        the community of learners that you belong to. Use specific details and
        examples to support your answer. Your response should be between 200-250
        words.
      </v-card-subtitle>
      <v-card-text>
        <v-label
          >I understand that the Ito Scholarship is a half-tuition
          scholarship.</v-label
        >
        <v-radio-group v-model="studentApplicationDynamicChanges.itoAgreement">
          <v-radio
            label="I understand that the Ito Scholarship is a half-tuition scholarship."
            :value="true"
          ></v-radio>
          <v-radio
            label="Never mind. I'm not interested in this scholarship."
            :value="false"
          ></v-radio>
        </v-radio-group>
        <v-textarea
          outlined
          class="mx-5"
          label="Essay"
          value=""
          auto-grow
          :rules="[rules.maxLength]"
          v-model="studentApplication.itoEssay"
        ></v-textarea>
      </v-card-text>
      <StudentNavigation />
    </v-card>
  </div>
</template>

<script>
// import { STEPS, COUNTRIES } from "@/constants.js";
import { mapState } from "vuex";
import StudentNavigation from "@/components/student/StudentNavigation.vue";
export default {
  name: "ITOScholarship",
  components: {
    StudentNavigation,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required",
      range: (value) => value >= 0 || "This value cannot be negative or empty",
      maxLength: (value) =>
        value.length <= 2500 ||
        "Length of essay may not exceed 2500 characters",
    },
  }),
  computed: {
    ...mapState("applicationStore", [
      "studentApplication",
      "studentApplicationDynamicChanges",
    ]),
  },
  methods: {},
  async created() {},
};
</script>

<style scoped>
.sizeRemove {
  max-width: 30px;
  max-height: 30px;
}
</style>
