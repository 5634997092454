import Vue from "vue";
import Vuex from "vuex";

import * as applicationStore from "./modules/ApplicationStore";
import * as adminStore from "./modules/AdminStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    applicationStore,
    adminStore,
  },
  state: {
    user: "",
    isLoading: false,
    jwtToken: [],
  },
  actions: {
    setIsLoading({ commit }, value) {
      commit("SET_IS_LOADING", value);
    },
    setUser({ commit }, value) {
      commit("SET_USER", value);
    },
  },
  mutations: {
    SET_IS_LOADING(state, value) {
      if (value === undefined || value === true) {
        state.isLoading = true;
      } else {
        state.isLoading = value;
      }
    },
    SET_USER(state, value) {
      if (value !== undefined) {
        state.user = value;
      }
    },
    SET_JWT(state, token) {
      if (token !== undefined) {
        state.jwtToken[0] = token;
      }
    },
  },
});

export const getters = {};
