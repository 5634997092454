<template>
  <v-container>
    <v-row class="mt-5">
      <v-col xs="12" sm="6" md="4" class="mr-3">
        <div class="sidebar">
          <AdminSteps />
        </div>
      </v-col>
      <v-col xs="12" sm="6" md="6" elevation="25">
        <ManageSemesters v-if="currentAdminStep == steps.MANAGE_SEMESTERS" />
        <ManageAdmins v-if="currentAdminStep == steps.MANAGE_ADMINS" />
        <ManageApplications
          v-if="currentAdminStep == steps.MANAGE_APPLICATIONS"
        />
        <StudentEmail v-if="currentAdminStep == steps.MANAGE_EMAIL_STUDENT" />
        <BishopEmail v-if="currentAdminStep == steps.MANAGE_EMAIL_BISHOP" />
        <MangeBishopInstructions
          v-if="currentAdminStep == steps.MANAGE_EMAIL_BISHOP_INSTRUCTIONS"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADMIN_OPTIONS } from "@/constants.js";
import { mapState, mapActions } from "vuex";
import ManageAdmins from "@/components/admin/steps/ManageAdmins.vue";
import AdminSteps from "@/components/admin/AdminSteps.vue";
import ManageSemesters from "@/components/admin/steps/ManageSemesters.vue";
import StudentEmail from "@/components/admin/steps/ManageStudentEmail.vue";
import BishopEmail from "@/components/admin/steps/ManageBishopEmail.vue";
import ManageApplications from "@/components/admin/steps/ManageApplications.vue";

import MangeBishopInstructions from "@/components/admin/steps/MangeBishopInstructions.vue";

export default {
  name: "BishopPage",
  components: {
    AdminSteps,
    ManageAdmins,
    ManageSemesters,
    BishopEmail,
    StudentEmail,
    ManageApplications,
    MangeBishopInstructions,
  },
  data() {
    return {
      steps: ADMIN_OPTIONS,
    };
  },
  computed: {
    ...mapState("adminStore", ["currentAdminStep"]),
  },
  methods: {
    ...mapActions("adminStore", [
      "getBishopInstructionsEmailText",
      "getAllAdmins",
      "getStudentEmail",
      "getBishopEmail",
      "getSemesters",
      "getAllInfoSemesters",
    ]),
  },
  async created() {
    await this.getAllAdmins().catch((e) => console.error(e));
    await this.getStudentEmail().catch((e) => console.error(e));
    await this.getBishopEmail().catch((e) => console.error(e));
    await this.getSemesters().catch((e) => console.error(e));
    await this.getBishopInstructionsEmailText().catch((e) => console.error(e));
    await this.getAllInfoSemesters().catch((e) => console.error(e));
  },
};
</script>

<style scoped>
.docsDialog {
  height: 600px;
}
</style>
