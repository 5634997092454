import AdminService from "../services/AdminService";
import { ADMIN_OPTIONS } from "@/constants";

export const namespaced = true;
export const state = {
  isLoading: false,
  currentAdminStep: 1,
  admins: [],
  administrator: false,
  semesters: [],
  bishopEmail: {},
  bishopInstructionsEmail: {},
  studentEmail: {},
  allInfoSemesters: [],
};

export const actions = {
  setAdminCurrentStepDirectly({ commit }, step) {
    commit("SET_ADMIN_CURRENT_STEP_DIRECTLY", step);
  },
  async getAllAdmins({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getAllAdmins()
      .then((result) => {
        commit("SET_ALL_ADMINS", result);
        dispatch("setIsLoading", false, { root: true });
        commit("SET_ADMINISTRATOR");
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async postCreateAdmin({ commit, dispatch }, netid) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.postCreateAdmin(netid)
      .then((result) => {
        commit("CREATE_ADMIN", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async deleteAdmin({ commit, dispatch }, netid) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.deleteAdmin(netid)
      .then(() => {
        commit("DELETE_ADMIN", netid);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async getSemesters({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getSemesters()
      .then((result) => {
        commit("SET_SEMESTERS", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async updateSemester({ dispatch }, item) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.updateSemester(item)
      .then(() => {
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async getAllInfoSemesters({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getAllInfoSemesters()
      .then((result) => {
        commit("SET_ALL_INFO_SEMESTERS", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async updateDownloadInfoSemesters({ dispatch }, semester) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.updateDownloadInfoSemesters(semester.id)
      .then(() => {
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async postCreateSemester({ commit, dispatch }, semester) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.createSemester(semester)
      .then((result) => {
        commit("CREATE_SEMESTER", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async getStudentEmail({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getStudentEmail()
      .then((result) => {
        commit("SET_STUDENT_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async updateStudentEmail({ commit, dispatch }, item) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.updateStudentEmail(item)
      .then((result) => {
        commit("UPDATE_STUDENT_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async getBishopInstructionsEmailText({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getBishopInstructionsEmailText()
      .then((result) => {
        commit("SET_BISHOP_INSTRUCTIONS_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async updateBishopInstructionsEmailText({ commit, dispatch }, item) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.updateBishopInstructionsEmailText(item)
      .then((result) => {
        commit("UPDATE_BISHOP_INSTRUCTIONS_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async getBishopEmail({ commit, dispatch }) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.getBishopEmail()
      .then((result) => {
        commit("SET_BISHOP_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
  async updateBishopEmail({ commit, dispatch }, item) {
    dispatch("setIsLoading", true, { root: true });
    await AdminService.updateBishopEmail(item)
      .then((result) => {
        commit("UPDATE_BISHOP_EMAIL", result);
        dispatch("setIsLoading", false, { root: true });
      })
      .catch((e) => {
        console.error(e);
        dispatch("setIsLoading", false, { root: true });
      });
  },
};

export const mutations = {
  SET_IS_LOADING(state, value) {
    if (value === undefined) {
      state.isLoading = true;
    } else {
      state.isLoading = value;
    }
  },
  SET_ADMIN_CURRENT_STEP_DIRECTLY(state, step) {
    if (
      step <= ADMIN_OPTIONS.MANAGE_EMAIL_BISHOP ||
      step >= ADMIN_OPTIONS.MANAGE_SEMESTERS
    ) {
      state.currentAdminStep = step;
    }
  },
  SET_ALL_ADMINS(state, payload) {
    state.admins = payload.data.reverse();
  },
  SET_ADMINISTRATOR(state) {
    state.administrator = true;
  },
  CREATE_ADMIN(state, payload) {
    state.admins.unshift(payload.data);
  },
  DELETE_ADMIN(state, netid) {
    state.admins = state.admins.filter((admin) => admin.id !== netid);
  },
  SET_SEMESTERS(state, payload) {
    state.semesters = payload.data.reverse();
  },
  SET_ALL_INFO_SEMESTERS(state, payload) {
    state.allInfoSemesters = payload.data.reverse();
  },
  CREATE_SEMESTER(state, payload) {
    state.semesters.unshift(payload.data);
  },
  SET_STUDENT_EMAIL(state, payload) {
    state.studentEmail = payload.data;
  },
  UPDATE_STUDENT_EMAIL(state) {
    state.studentEmail.email_original = state.studentEmail.email_text;
  },
  SET_BISHOP_INSTRUCTIONS_EMAIL(state, payload) {
    state.bishopInstructionsEmail = payload.data;
  },
  UPDATE_BISHOP_INSTRUCTIONS_EMAIL(state) {
    state.bishopInstructionsEmail.email_original =
      state.bishopInstructionsEmail.email_text;
  },
  SET_BISHOP_EMAIL(state, payload) {
    state.bishopEmail = payload.data;
  },
  UPDATE_BISHOP_EMAIL(state) {
    state.bishopEmail.email_original = state.bishopEmail.email_text;
  },
};

export const getters = {};
