var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mb-5",attrs:{"elevation":"25","outlined":""}},[_c('v-card-title',[_vm._v(" Manage Applications "),_c('v-spacer')],1),_c('v-card-subtitle',[_vm._v("Each accordion will expand to show several options. Each option will have a csv download for that specific semester. ")]),_c('v-card-subtitle',[_vm._v("Also If a button does not download an excel spreadsheet it could mean there is no data to download. Please do not click the button multiple times in a row. If you aren't getting the result you want please wait 5 secs before clicking it again. Do this a couple times and if you believe there should be data please contact a developer to figure out the issue. ")]),_c('v-expansion-panels',_vm._l((_vm.allInfoSemesters),function(allInfoSemester){return _c('v-expansion-panel',{key:allInfoSemester.id},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(allInfoSemester.semesterName)+" ")]),_c('v-expansion-panel-content',{staticClass:"mb-2"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-2",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"primary"},[_c('downloadExcel',{attrs:{"data":allInfoSemester.student_application.filter(
											(x) =>
												x.tomBeckySmithEssay
													? x.tomBeckySmithEssay
															.length >= 1
													: false
										),"fields":_vm.tbsFields,"name":allInfoSemester.semesterName +
										' TBS.xls'}},[_vm._v(" Tom Becky Smith ")])],1)],1),_c('v-col',{attrs:{"sm":"12","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.markApplicationsDownloaded(
										allInfoSemester
									)}}},[_c('downloadExcel',{attrs:{"data":allInfoSemester.student_application.filter(
											(x) => x.downloaded == 0
										),"name":allInfoSemester.semesterName +
										' Newest Applications.xls',"fields":_vm.allFields,"escapeCsv":false,"before-generate":_vm.pullInfoBeforeDownload}},[_vm._v(" New Applications ")])],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"primary"},[_c('downloadExcel',{attrs:{"data":allInfoSemester.student_application.filter(
											(x) =>
												x.itoEssay
													? x.itoEssay.length >= 1
													: false
										),"fields":_vm.itoFields,"separator-excel":true,"name":allInfoSemester.semesterName +
										' Ito.xls'}},[_vm._v(" Ito ")])],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"primary"},[_c('downloadExcel',{attrs:{"data":allInfoSemester.student_application,"name":allInfoSemester.semesterName +
										' ALL Applications.xls',"fields":_vm.allFields,"escapeCsv":false}},[_vm._v(" All Applications ")])],1)],1),_c('v-col',{staticClass:"mb-2",attrs:{"sm":"12","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"primary"},[_c('downloadExcel',{attrs:{"data":allInfoSemester.student_application.filter(
											(x) =>
												x.return_missionary_scholarship
										),"name":allInfoSemester.semesterName +
										' RM.xls',"fields":_vm.rmFields,"escapeCsv":false}},[_vm._v(" RM Scholarship ")])],1)],1)],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }