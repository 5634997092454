<template>
  <div>
    <v-card class="steps" outlined>
      <v-card-text
        :class="stepColor(steps.MANAGE_SEMESTERS)"
        @click="setAdminCurrentStepDirectly(steps.MANAGE_SEMESTERS)"
      >
        <v-row>
          <v-col cols="10"> Manage Semesters</v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.MANAGE_ADMINS)"
        @click="setAdminCurrentStepDirectly(steps.MANAGE_ADMINS)"
      >
        <v-row>
          <v-col cols="10"> Manage Admins </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.MANAGE_APPLICATIONS)"
        @click="setAdminCurrentStepDirectly(steps.MANAGE_APPLICATIONS)"
      >
        <v-row>
          <v-col cols="10"> Manage Applications </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.MANAGE_EMAIL_STUDENT)"
        @click="setAdminCurrentStepDirectly(steps.MANAGE_EMAIL_STUDENT)"
      >
        Manage Student Email
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.MANAGE_EMAIL_BISHOP_INSTRUCTIONS)"
        @click="
          setAdminCurrentStepDirectly(steps.MANAGE_EMAIL_BISHOP_INSTRUCTIONS)
        "
      >
        Manage Bishop Instructions Email
      </v-card-text>
      <v-divider />
      <v-card-text
        :class="stepColor(steps.MANAGE_EMAIL_BISHOP)"
        @click="setAdminCurrentStepDirectly(steps.MANAGE_EMAIL_BISHOP)"
      >
        Manage Bishop Email
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ADMIN_OPTIONS } from "../../constants.js";
import { mapState, mapActions } from "vuex";
export default {
  props: [],
  name: "AdminSteps",
  data: () => ({
    steps: ADMIN_OPTIONS,
  }),
  computed: {
    ...mapState("adminStore", ["currentAdminStep"]),
  },
  methods: {
    ...mapActions("adminStore", ["setAdminCurrentStepDirectly"]),
    stepColor(val) {
      return this.currentAdminStep == val
        ? "secondary font-weight-bold white--text hover"
        : "py-2 hover";
    },
  },
};
</script>

<style scoped>
.steps {
  min-width: 300px;
}

v-card-text {
  font-size: 0.75em;
}

.hover {
  cursor: pointer;
}
</style>
